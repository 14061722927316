import React from 'react'

const Security = ({ formData17, setFormData17, handleInputChange }) => {
  return (
    <div>
      <h2 className='fw-bold'>GRI 410: Security Practices:</h2>
    
      <div className='m-3'>
        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="list_security_training_programs_provided" className="me-2 mb-2 flex-1">(GRI 410-1) Security Personnel Training</label>
          <textarea rows="3" className='custom-input flex-1' id="list_security_training_programs_provided" name="list_security_training_programs_provided" value={formData17.list_security_training_programs_provided} onChange={(e) => handleInputChange(e, setFormData17)}></textarea>
      </div>

      <div className="mb-4 d-flex align-items-center">
        <label htmlFor="list_incidents_involving_security_personnel" className="me-2 mb-2 flex-1">(GRI 410-2): Incidents of Human Rights Violations Involving Security Personnel</label>
        <textarea rows="3" className='custom-input flex-1' id="list_incidents_involving_security_personnel" name="list_incidents_involving_security_personnel" value={formData17.list_incidents_involving_security_personnel} onChange={(e) => handleInputChange(e, setFormData17)}></textarea>
      </div>

    </div>
  </div>
  )
}

export default Security