import React from 'react'
import './Forms.css'

const Progressbar = ({ currentStep }) => {
  return (
    <div className="container">
        <div className="row">
          <div className="progressbar">
          {/* show a small percentage of the progress bar when the user goes to the next page */}
          <div style={{ width: currentStep === 0 ? "14.3%" : currentStep === 1 ? "28.57%" : 
          currentStep === 2 ? "42.87%" : currentStep === 3 ? "57.17%" :
          currentStep === 4 ? "71.47%" :  currentStep === 5 ? "85.77%" :
           currentStep === 6 ? "100%": "100%"}}></div>
        </div> 
      </div>
    </div>      
  )
}

export default Progressbar