import React from 'react';
import './About.css'

const AboutApproach = () => {
  return (
    <div className="container" id="custom cards" >
       <h2 className="pb-2 border-bottom fw-bold" id="image-cards-title">Approach</h2> 
        <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-3">
          <div className="col">
            <div id="image1" className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg-md-sm">
              <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                <h3 id="text1" className="pt-5 mb-4 lh-2 fw-bold" style={{fontSize:'2rem'}}>Automation and Predictive Analysis</h3>
                <p className="fw-bold">Streamlining sustainability reporting through automated processes and advanced analytics using AI and Machine Learning.</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div id="image2" className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg-md-sm">
              <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                <h3 id="text2" className="pt-5 mb-4 lh-2 fw-bold" style={{fontSize:'2rem'}}>Environmental Metrics</h3>
                <p className="fw-bold">Empowering entities to quantify and report environmental data through our streamlined reporting platform for enhanced transparency.</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div id="image3" className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg-md-sm">
              <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                <h3 className="pt-5 mb-4 lh-2 fw-bold" style={{fontSize:'2rem'}}>Socially Inclusive Collaboration</h3>
                <p className="fw-bold">Fostering an inclusive environment where all entities share and adopt best sustainability practices together.</p>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default AboutApproach;
