import React, { useState } from "react";
import {
  ChildLabor,
  Diversity,
  Employment,
  ForcedLabor,
  Freedom,
  GenderDiversity,
  LaborManagement,
  NonDiscrimination,
  OccupationalHealth,
  Security,
  TrainingEducation,
} from "./SocialIndividualForms";
import SocialProgressbar from "./SocialProgressbar";
import "./Forms.css";
import axios from "axios";
import { useCookies } from "react-cookie";
import Config from "../../../config/Config";


// Define UserDropdown component
export const UserDropdown = ({ options, value, onChange, name }) => {
  return (
    <select
      value={value}
      onChange={onChange}
      name={name}
      className="form-select flex-1"
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

// collective bargaining agreements
const collectiveBargainigOptions = [
  { value: "", label: "Select an option" },
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

// custom hook to manage the state for different forms
const useFormState = (fields) => {
  const initialState = fields.reduce((state, field) => {
    state[field.name] = field.initialValue;
    return state;
  }, {});

  const [formData, setFormData] = useState(initialState);

  return [formData, setFormData];
};

const SocialMultiform = ({ setActiveComponent }) => {
  const [cookies] = useCookies(["id", "companyId"]);

  // to show the multistep form after the social reporting button is clicked
  const [showMultiform, setshowMultiform] = useState(false);

  // Store the current step of the form in a state
  const [currentStep, setCurrentStep] = useState(1);

  const handleStartReporting = () => {
    // Set the state to show the multistep form
    setshowMultiform(true);
  };

  // Rest of the component code
  const [formData7, setFormData7] = useFormState([
    { name: "total_number_of_employees", initialValue: 0 },
    { name: "total_number_of_employees_20_30", initialValue: 0 },
    { name: "total_number_of_employees_31_40", initialValue: 0 },
    { name: "total_number_of_employees_41_50", initialValue: 0 },
    { name: "total_number_of_employees_51_60", initialValue: 0 },
    { name: "total_number_of_employees_61", initialValue: 0 },
  ]);
  const [formData8, setFormData8] = useFormState([
    { name: "total_number_of_male_employees", initialValue: 0 },
    { name: "total_number_of_female_employees", initialValue: 0 },
    { name: "rate_employee_turnovers", initialValue: 0 },
    { name: "total_number_employees_training", initialValue: 0 },
    { name: "list_employee_benefits", initialValue: "" },
  ]);
  const [formData9, setFormData9] = useFormState([
    { name: "collective_bargaining_agreements", initialValue: "" },
    { name: "total_number_strikes_lockouts", initialValue: 0 },
    { name: "list_disputes_due_to_labor_practices", initialValue: "" },
  ]);
  const [formData10, setFormData10] = useFormState([
    { name: "total_number_occupational_accidents", initialValue: 0 },
    { name: "total_number_occupational_diseases", initialValue: 0 },
    { name: "list_health_safety_training_programs", initialValue: "" },
    { name: "emergency_response_plans_procedures", initialValue: "" },
  ]);
  const [formData11, setFormData11] = useFormState([
    { name: "list_employee_training_programs", initialValue: "" },
    { name: "list_education_finance_assistance_provided", initialValue: "" },
    { name: "list_opportunities_career_advancement", initialValue: "" },
  ]);
  const [formData12, setFormData12] = useFormState([
    { name: "list_diversity_policies_initiatives", initialValue: "" },
    { name: "list_equal_pay_practices", initialValue: "" },
  ]);
  const [formData13, setFormData13] = useFormState([
    { name: "list_non_discrimination_policies", initialValue: "" },
    { name: "list_incidents_discrimination", initialValue: "" },
  ]);
  const [formData14, setFormData14] = useFormState([
    { name: "policies_freedom_association", initialValue: "" },
    { name: "list_agreements_with_labour_unions", initialValue: "" },  
  ]);
  const [formData15, setFormData15] = useFormState([
    { name: "child_labor_incidents", initialValue: "" },
    { name: "actions_taken_against_child_labor", initialValue: "" },   
  ]);
  const [formData16, setFormData16] = useFormState([
    { name: "forced_labor_incidents", initialValue: "" },
    { name: "actions_taken_against_forced_labor", initialValue: "" },   
  ]);
  const [formData17, setFormData17] = useFormState([
    { name: "list_security_training_programs_provided", initialValue: "" },
    { name: "list_incidents_involving_security_personnel", initialValue: "" },   
  ]);

  const handleNext = () => {
    // Handle next logic
    if (currentStep < 11) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    // Handle previous logic
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleInputChange = (e, setFormData) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async () => {
    const userId = cookies.id;

    const allFormData = {
      ...formData7,
      ...formData8,
      ...formData9,
      ...formData10,
      ...formData11,
      ...formData12,
      ...formData13,
      ...formData14,
      ...formData15,
      ...formData16,
      ...formData17,
      created_by: userId,
    };

    //  Send allFormData to Django endpoint using Axios
    try {
      const companyId = cookies.companyId;

      const response = await axios.post(
        `${Config.apiBaseUrl}/companies/${companyId}/social/`,
        allFormData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Form submitted successfully:", response.data);
      setshowMultiform(false);

      setActiveComponent('ShowCharts');
      
    } catch (error) {
      // Handle specific errors
      if (error.isAxiosError) {
        // Check if it's an Axios error
        if (error.response) {
          console.error("Status:", error.response.status);
          console.error("Error message:", error.response.data);
        } else if (error.request) {
          // The request was made but no response was received e.g. Server connection error
          console.error("Network error:", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error:", error.message);
        }
      } else {
        // Handle other non-Axios errors (optional)
        console.error("Unexpected error:", error);
      }
    }
  };

  const renderFormInputs = () => {
    // multistep form input rendering logic
    switch (currentStep) {
      // Introduction
      case 1:
        return (
          <Employment
            formData7={formData7}
            setFormData7={setFormData7}
            handleInputChange={handleInputChange}
          />
        );

      // Materials form
      case 2:
        return (
          <GenderDiversity
            formData8={formData8}
            setFormData8={setFormData8}
            handleInputChange={handleInputChange}
          />
        );
      // Materials form
      case 3:
        return (
          <LaborManagement
            formData9={formData9}
            setFormData9={setFormData9}
            collectiveBargainigOptions={collectiveBargainigOptions}
            handleInputChange={handleInputChange}
          />
        );

      // {/*// Energy form */}
      case 4:
        return (
          <OccupationalHealth
            formData10={formData10}
            setFormData10={setFormData10}
            handleInputChange={handleInputChange}
          />
        );

      // {/* // Water form */}
      case 5:
        return (
          <TrainingEducation
            formData11={formData11}
            setFormData11={setFormData11}
            handleInputChange={handleInputChange}
          />
        );

      // {/* // Biodiversity form */}
      case 6:
        return (
          <Diversity
            formData12={formData12}
            setFormData12={setFormData12}
            handleInputChange={handleInputChange}
          />
        );

      //  {/* // Emissions form */}
      case 7:
        return (
          <NonDiscrimination
            formData13={formData13}
            setFormData13={setFormData13}
            handleInputChange={handleInputChange}
          />
        );

      // {/* // Effluents and Waste */}
      case 8:
        return (
          <Freedom
            formData14={formData14}
            setFormData14={setFormData14}
            handleInputChange={handleInputChange}
          />
        );

      case 9:
        return (
          <ChildLabor
            formData15={formData15}
            setFormData15={setFormData15}
            handleInputChange={handleInputChange}
          />
        );

      case 10:
        return (
          <ForcedLabor
            formData16={formData16}
            setFormData16={setFormData16}
            handleInputChange={handleInputChange}
          />
        );

      case 11:
        return (
          <Security
            formData17={formData17}
            setFormData17={setFormData17}
            handleInputChange={handleInputChange}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className="container mt-3">
      <div className="overview-boxes">
        {/* show the multiform if true */}
        {showMultiform ? (
          <div className="box" id="report-form">
            <div className="right-side">
              <form action="">
                {renderFormInputs()}
                <br />
                {currentStep > 1 && (
                  <button
                    className="prev-btn mt-2 btn btn-secondary rounded-pill me-2"
                    type="button"
                    onClick={handlePrevious}
                  >
                    Previous
                  </button>
                )}
                {currentStep < 11 ? (
                  <button
                    type="button"
                    className="next-btn mt-2 btn btn-success rounded-pill"
                    onClick={handleNext}
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    className="next-btn mt-2 btn btn-success rounded-pill"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
              </form>
              <div>
                {/* Progress bar */}
                <SocialProgressbar currentStep={currentStep} />
              </div>
            </div>
          </div>
        ) : null}

        {/* Show the "Start social reporting" button if not showing charts */}
        {!showMultiform && (
          <div>
            <button
              type="button"
              className="btn btn-primary mt-4"
              onClick={() => {
                handleStartReporting();
              }}
            >
              Start social reporting
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SocialMultiform;
