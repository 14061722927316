import Esg from '../components/Dashboard/Esg';

const Dashboard = () => {
  
  return (
    <div>
      <Esg/>
    </div>
  );
}

export default Dashboard;
