import React from 'react';
import evs1Img from '../../assets/evs1.jpg'
import communityImg from '../../assets/community.png'
import collaborateImg from '../../assets/collaborate.png'
import { Link } from 'react-router-dom';
import './About.css'

function AboutFeatures() {
  return (
    <div>
      <section id="features" className="features mt-2">
        <div className="container" data-aos="fade-up">
          <ul className="nav nav-tabs row d-flex">
            <li className="nav-item col-4">
              <Link className="nav-link active show" data-bs-toggle="tab" to="#tab-1">
                <i className='bx bx-world'></i>
                <h4 className="d-none d-lg-block">Carbon Market</h4>
              </Link>
            </li>
            <li className="nav-item col-4">
              <Link className="nav-link" data-bs-toggle="tab" to="#tab-2">
              <i className='bx bx-group'></i>
                <h4 className="d-none d-lg-block">Community participation</h4>
              </Link>
            </li>
            <li className="nav-item col-4">
              <Link className="nav-link" data-bs-toggle="tab" to="#tab-3">
                <i className='bx bxs-message'></i>
                <h4 className="d-none d-lg-block">Join Us</h4>
              </Link>
            </li>       
          </ul>

          <div className="tab-content">
            <div className="tab-pane active show" id="tab-1">
              <div className="row">
                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                  <h3>Carbon Credits</h3>
                  <p className="fst-italic">
                    GreenLead transforms environmental stewardship by employing the Avoided emission concept in solar energy and e-mobility.
                    By offering incentives through carbon credits in carbon markets, we accelerate the shift towards sustainable practices.
                    Our transparent ledger builds trust, allowing buyers to scrutinize sources meticulously.
                  </p>

                  <p className="fst-italic">
                    At GreenLead, we believe that incentivizing the adoption of climate products and services will hasten the transition towards building green economies
                    This commitment accelerates the transition to impactful and responsible environmental initiatives, propelling
                    a faster adoption of sustainable practices in both solar energy and e-mobility.

                  </p>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 text-center">
                  <img src={evs1Img} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="tab-pane" id="tab-2">
              <div className="row">
                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                  <h3>Community engagement and development</h3>
                  <p className="fst-italic">
                    At the core of GreenLead is community empowerment. We firmly believe that collective action is indispensable for achieving our environmental preservation goals.
                    We actively engage with local communities, stakeholders and volunteers to foster a sense of ownership, environmental stewardship and social inclusion.
                  </p>
                  <p className="fst-italic">
                    Through workshops, educational programs and collaborative initiatives, we empower individuals to engage in environmentally sustainable activities and raise awareness about protecting our natural resources.
                    By building a robust community network, we create a platform for knowledge exchange, collaboration and collective impact.
                    Together, we can make a lasting difference in preserving our environment and building a sustainable future for generations to come.
                  </p>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 text-center">
                  <img src={communityImg} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="tab-pane" id="tab-3">
              <div className="row">
                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                  <h3>Call to Action</h3>
                  <p className="fst-italic">
                    Join GreenLead in our mission to promote environmental conservation and social inclusion.
                    Whether you're an individual, organization, or business, there are many ways to get involved. Become a partner, contribute to our initiatives, or support us.
                    Help us raise awareness about the importance of sustainable practices.
                  </p>
                  <p className="fst-italic">
                    Together, we can create a greener, more sustainable future. Contact us today to play a vital role in preserving our planet
                     for future generations. Be part of the environmental revolution with GreenLead.
                  </p>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 text-center">
                  <img src={collaborateImg} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
           </div>
        </div>
      </section>
    </div>
  );
}

export default AboutFeatures;
