import React from "react";
import GL1 from "../assets/GL1.jpg";
import "./CommonComponents.css";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

const Navbar = () => {
  const [cookies] = useCookies(["id"]);
  const isLoggedIn = cookies.id !== undefined;

  const [, , removeCookie] = useCookies(["g_accessToken", "username", "id"]);

  const handleLogout = () => {
    removeCookie("g_accessToken", { path: "/" });
    removeCookie("username", { path: "/" });
    removeCookie("id", { path: "/" });
    window.location.href = "/";
    return false;
  };

  return (
    <nav className="navbar navbar-expand-lg" id="navigation">
      <div className="container d-flex justify-content-between align-items-center">
        {/* the logo */}
        <Link
          to="/"
          className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none"
        >
          <img
            id="logo-img"
            src={GL1}
            alt=""
            className="logo-image me-1"
            width="70"
            height="70"
            role="img"
          />
          <span className="logo" id="logo">
            GreenLead
          </span>
        </Link>

        {/* dropdown button */}
        <button
          className="navbar-toggler nav-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarColor02"
          aria-controls="navbarColor02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="container-fluid">
          <div className="row">
            <div className="col-8"></div>

            <div className="col-4 navbar-collapse collapse" id="navbarColor02">
              <ul className="nav nav-pills">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle show"
                    id="drop"
                    data-bs-toggle="dropdown"
                    href="index.html"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    Explore
                  </a>
                  <div
                    id="glass"
                    className="dropdown-menu hide"
                    data-popper-placement="bottom-start"
                  >
                    <Link to="/about" className="dropdown-item">
                      About Us
                    </Link>
                    {isLoggedIn ? (
                      <Link to="#" className="dropdown-item" onClick={handleLogout}>
                        Logout
                      </Link>
                    ) : (
                      <Link to="/login" className="dropdown-item">
                        Login
                      </Link>
                    )}
                    {/* <Link to='/signup' className="dropdown-item">Signup</Link> */}

                    <div className="dropdown-divider"></div>
                    <h6 id="tree">ESG Dashboard</h6>
                    <Link to="/dashboard" className="dropdown-item">
                      Dashboard
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
