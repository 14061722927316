import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Dashboard.css";
import TopNavbar from "./TopNavbar";
import SidebarDashboard from "./SidebarDashboard";
import EnvironmentalMultiform from "./Forms/EnvironmentalMultiform";
import ShowCharts from "./Forms/ShowCharts";
import SocialMultiform from "./Forms/SocialMultiform";
import ESGreport from "../../assets/ESG-report (1).jpg";
import ESGreport1 from "../../assets/ESG-report.jpg";

import { useCookies } from "react-cookie";

const Esg = () => {
  const [sidebar, setSidebar] = useState(false);

  const [activeComponent, setActiveComponent] = useState("ShowCharts");

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  const [searchValue, setSearchValue] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const [cookies] = useCookies(["id"]);
  const isLoggedIn = cookies.id !== undefined;

  return (
    <div>
      {/* Sidebar Dashboard */}

      <SidebarDashboard sidebar={sidebar} />
      <section className="home-section">
        {/* Dashboard Navbar */}
        <TopNavbar toggleSidebar={toggleSidebar} />

        {/* Body content */}
        <div className="home-content">
          <div className="sales-boxes responsive">
            <div className="recent-sales box">
              <div className="container mt-3">
                <div className="row">
                  <div
                    id="export-csv"
                    className="col-md-8 d-flex justify-content-between"
                  >
                    <div id="export-csv" className="col-md-3">
                      <div className="dropdown">
                        <button
                          className="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="yearDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Year
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="yearDropdown"
                        >
                          <li>
                            <Link className="dropdown-item" to="#">
                              2023
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              2022
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              2021
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              2020
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              2019
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              2018
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              2017
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div id="export-csv" className="col-md-3">
                      <div className="dropdown">
                        <button
                          className="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="monthDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Month
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="monthDropdown"
                        >
                          <li>
                            <Link className="dropdown-item" to="#">
                              January
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              February
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              March
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              April
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              May
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              June
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              July
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              August
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              September
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              October
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              November
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              December
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {isLoggedIn ? null : (
                      <div id="export-csv" className="col-md-3">
                        <a
                          type="button"
                          className="btn btn-primary"
                          href="https://forms.gle/XpU1LomwpAjQWTNE8"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Demo
                        </a>
                      </div>
                    )}
                    {/* <div
                        className="modal fade"
                        id="staticBackdrop"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabIndex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1
                                className="modal-title fs-5 fw-bold"
                                id="staticBackdropLabel"
                              >
                                Premium Subscriptions
                              </h1>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <ul>
                                <li>Download data in CSV and PDF formats</li>
                                <li>
                                  Enhanced Analytics using our machine learning
                                  models
                                </li>
                                <li>Compare with industry players</li>
                                <li>Consultation Services</li>
                              </ul>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-sm btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <Link
                                to="https://forms.gle/XpU1LomwpAjQWTNE8"
                                className="btn btn-sm btn-success btn-rounded"
                              >
                                Book Demo
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div> */}

                    {isLoggedIn ? (
                      // if logged in show the report button
                      <div id="export-csv" className="col-md-3">
                        {/* When the button is clicked, show the form */}
                        {activeComponent === "ShowCharts" && (
                          <button
                            id="report-btn"
                            className="btn btn-danger btn-rounded"
                            onClick={() => {
                              setActiveComponent("EnvironmentalMultiform");
                            }}
                          >
                            Report
                          </button>
                        )}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4">
                    <div id="search-input" className="form-group mb-3">
                      <input
                        type="text"
                        id="searchField"
                        className="form-control"
                        placeholder="Search by Company"
                        value={searchValue}
                        onChange={handleSearchInputChange}
                      />
                    </div>
                  </div>
                </div>
                {isLoggedIn ? (
                  <div>
                    {activeComponent === "ShowCharts" && <ShowCharts />}
                    {activeComponent === "EnvironmentalMultiform" && (
                      <EnvironmentalMultiform
                        setActiveComponent={setActiveComponent}
                      />
                    )}
                    {activeComponent === "SocialMultiform" && (
                      <SocialMultiform
                        setActiveComponent={setActiveComponent}
                      />
                    )}
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-md-6">
                      <img src={ESGreport} alt="first" className="img-fluid" />
                    </div>
                    <div className="col-md-6">
                      <img
                        src={ESGreport1}
                        alt="second2"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Esg;
