import React from 'react'

const GenderDiversity = ({ formData8, setFormData8, handleInputChange }) => {
  return (
    <div>
      <div>
        <h3 className='fw-bold mb-4'>(GRI 401-2): Gender Diversity</h3>
        <div className="mb-4 d-flex">
          <label htmlFor="biodiversity-sites" className="mb-2 flex-1">Number of male employees</label>
          <input type="number" className='custom-input flex-1' id="biodiversity-sites" name="total_number_of_male_employees"  value={formData8.total_number_of_male_employees} onChange={(e) => handleInputChange(e, setFormData8)}/>
        </div>
        <div className="mb-4 d-flex">
          <label htmlFor="biodiversity-sites" className="mb-2 flex-1">Number of female employees</label>
          <input type="number" className='custom-input flex-1' id="biodiversity-sites" name="total_number_of_female_employees" value={formData8.total_number_of_female_employees} onChange={(e) => handleInputChange(e, setFormData8)} />
        </div>
      </div>

      <div className="me-3 mb-4 d-flex align-items-center">
        <label htmlFor="rate_employee_turnovers" className='flex-1'>(GRI 401-3): Employee Turnover</label>
        <input type="number" name="rate_employee_turnovers" className='custom-input flex-1' id="rate_employee_turnovers" value={formData8.rate_employee_turnovers} onChange={(e) => handleInputChange(e, setFormData8)} />
      </div>

      <div className="me-3 mb-4 d-flex align-items-center">
        <label htmlFor="total_number_employees_training" className='flex-1'>(GRI 401-4): Employee Training</label>
        <input type="number" name="total_number_employees_training" className='custom-input flex-1' id="total_number_employees_training" value={formData8.total_number_employees_training} onChange={(e) => handleInputChange(e, setFormData8)} />
      </div>

      <div className="me-3 mb-4 d-flex align-items-center">
        <label htmlFor="list_employee_benefits" className='flex-1'>(GRI 401-5): Employee Benefits </label>
        <textarea name="list_employee_benefits" className='custom-input flex-1' id="list_employee_benefits" rows='3' value={formData8.list_employee_benefits} onChange={(e) => handleInputChange(e, setFormData8)}></textarea>
      </div>
    </div>
  )
}

export default GenderDiversity