/////////////////POLAR AREA CHART////////////////////////

import React from "react";
import { PolarArea } from "react-chartjs-2";

import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

import { Chart } from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ChartDataLabels);

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const PolarAreaChart = ({ data }) => {
  const chartData = {
    labels: [
      "operational_sites",
      "adjacent_operational_sites",
      "sites_outside_protected_areas",
      "sites_located_in_high_biodiversity_areas",
    ],
    datasets: [
      {
        data: [
          data.operational_sites,
          data.adjacent_operational_sites,
          data.sites_outside_protected_areas,
          data.sites_located_in_high_biodiversity_areas,
        ],
        backgroundColor: [
          "rgba(75,192,192,1)",
          "rgba(255,99,132,1)",
          "rgba(255,206,86,1)",
          "rgba(54,162,235,1)",
        ],
        borderColor: [
          "rgba(75,192,192,1)",
          "rgba(255,99,132,1)",
          "rgba(255,206,86,1)",
          "rgba(54,162,235,1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const total = chartData.datasets[0].data.reduce((a, b) => a + b, 0);

  return (
    <div
      style={{
        width: "100%",
        height: '100%',
        marginBottom: "1.5rem",
        marginTop: "4rem",
        padding: "1rem",
        boxShadow: "0.5px 1px 3px 2px rgba(80, 165, 98, 0.5)",
        borderRadius: '0.5rem'
      }}
    >
      <h6
        style={{
          color: "#717171",
          fontSize: "12px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Operational Sites
      </h6>
      <PolarArea
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            plugins: [ChartDataLabels],
            legend: {
              display: true,
              position: "top",
            },
            datalabels: {
              formatter: function (value) {
                let percentage = ((value / total) * 100).toFixed(2);
                return percentage + "%";
              },
              color: "#717171",
              anchor: "end",
              align: "end",
              offset: 5,
              padding: -2,
              clip: true,
              font: {
                size: "11",
                weight: "bold",
              },
            },
            title: {
              display: true,
              text: "Operational Sites Polar Area Chart",
            },
          },
        }}
      />
    </div>
  );
};

export default PolarAreaChart;
