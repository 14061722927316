import React from 'react'

const Diversity = ({ formData12, setFormData12, handleInputChange }) => {
  return (
    <div>
      <h2 className='fw-bold'>GRI 405: Diversity and Equal Opportunity:</h2>
      
      <div>
        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="list_diversity_policies_initiatives" className="me-2 mb-2 flex-1">(GRI 405-1): Diversity Policies</label>
          <textarea rows="3" className='custom-input flex-1' id="list_diversity_policies_initiatives" name="list_diversity_policies_initiatives" value={formData12.list_diversity_policies_initiatives} onChange={(e) => handleInputChange(e, setFormData12)}></textarea>
        </div>

        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="list_equal_pay_practices" className="me-2 mb-2 flex-1">(GRI 405-2): Equal Pay Practices</label>
          <textarea rows="3" className='custom-input flex-1' id="list_equal_pay_practices" name="list_equal_pay_practices" value={formData12.list_equal_pay_practices} onChange={(e) => handleInputChange(e, setFormData12)}></textarea>
        </div>
      </div>
    </div>
  )
}

export default Diversity