import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie'; // Import useCookies hook
import Config from '../../config/Config';
import ClipLoader from 'react-spinners/ClipLoader'; // Import loading spinner
import './login.css';

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long'),
});

const Login = () => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['g_accessToken', 'username', 'id', 'companyId']); // Use cookies instead of localStorage
  const [loading, setLoading] = useState(false); // State for loading spinner

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true); // Show loading spinner
    try {
      const response = await axios.post(`${Config.apiBaseUrl}/v1/sign_in/authenticate/`, values);
      console.log('Login successful', response.data);

      // Print the access token to the screen
      console.log(response.data.data.token);

      // Set cookie with the access token
      setCookie('g_accessToken', response.data.data.token, { path: '/' });
      setCookie('username', response.data.data.userInformation.username, { path: '/' });
      setCookie('id', response.data.data.userInformation.id, { path: '/' });
      setCookie('companyId', response.data.data.user_Profile.company, { path: '/' });

      navigate("/dashboard");
    } catch (error) {
      console.error('Login failed', error);
      // Handle error, show error message, etc.
    } finally {
      setSubmitting(false);
      setLoading(false); // Hide loading spinner
    }
  };

  return (
      <div className="containerl">
        <div className="centered-content"> {/* Centering container */}
          <div className="screen">
            <div className="screen__content">
              <Formik
                  initialValues={{ username: '', password: '' }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                    <Form className="login">
                      <div className="login__field">
                        <box-icon className="login_icon" type='solid' name='user'></box-icon>
                        <Field type="text" className="login__input" placeholder="Username" name="username" id="usernameField" />
                        <ErrorMessage name="username" component="div" className="error" />
                      </div>

                      <div className="login__field">
                        <box-icon className="login_icon" type='solid' name='lock-open-alt'></box-icon>
                        <Field type="password" className="login__input" placeholder="Password" name="password" id="passwordField" />
                        <ErrorMessage name="password" component="div" className="error" />
                      </div>

                      <button type="submit" className="button login__submit" disabled={isSubmitting || loading}>
                        {loading ? <ClipLoader size={24} color="#26703c" /> : 'Login'}
                      </button>
                    </Form>
                )}
              </Formik>
            </div>
            <div className="screen__background">
              <span className="screen__background__shape screen__background__shape4"></span>
              <span className="screen__background__shape screen__background__shape3"></span>
              <span className="screen__background__shape screen__background__shape2"></span>
              <span className="screen__background__shape screen__background__shape1"></span>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Login;
