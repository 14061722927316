import React, { useState } from "react";
import esg1 from "../../assets/esg1.jpg";
import artificial from "../../assets/artificial.jpg";
import report from "../../assets/report.jpg";
import info from "../../assets/info.jpg";
import dashboard1 from "../../assets/dashboard1.jpg";
import { Link } from "react-router-dom";

const HowItWorks = () => {
  const [tabIndex, setTabIndex] = useState(1);

  const handleTabChange = (index) => {
    setTabIndex(index);
  };

  return (
    <>
      <section className="how-it-works mt-3" id="courses">
        <div className="container">
          <div className="row">
            <h2 className="pb-2 border-bottom fw-bold">
              How it<span className="service-heading"> Works</span>
            </h2>
            <div className="col-lg-12">
              <div className="naccs">
                <div className="tabs">
                  <div className="row py-3">
                    <div className="col-md-3">
                      <div className="menu">
                        <div
                          className={`gradient-border ${
                            tabIndex === 1 && "active"
                          }`}
                          onClick={() => handleTabChange(1)}
                        >
                          <span>Data Input</span>
                        </div>
                        <div
                          className={`gradient-border ${
                            tabIndex === 2 && "active"
                          }`}
                          onClick={() => handleTabChange(2)}
                        >
                          <span>Automated Analysis</span>
                        </div>
                        <div
                          className={`gradient-border ${
                            tabIndex === 3 && "active"
                          }`}
                          onClick={() => handleTabChange(3)}
                        >
                          <span>Data Visualization</span>
                        </div>
                        <div
                          className={`gradient-border ${
                            tabIndex === 4 && "active"
                          }`}
                          onClick={() => handleTabChange(4)}
                        >
                          <span>Insightful Reporting</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <ul className="nacc">
                        {tabIndex === 1 && (
                          <li className="tab-content active">
                            <>
                              <div className="left-image">
                                <img
                                  className="img-fluid"
                                  src={esg1}
                                  alt=""
                                  width="320px"
                                  height="320px"
                                />
                              </div>
                              <div className="right-content">
                                <h4>Data Input</h4>
                                <p>
                                  GreenLead initiates the account creation
                                  process for businesses, organizations and
                                  companies. Once established, user logs in and
                                  input their ESG data through user-friendly
                                  digital templates on their dashboard.
                                  <br />
                                  <br />
                                  These templates cover various environmental,
                                  social and governance aspects, from energy
                                  consumption and waste management to employee
                                  demographics and community engagement etc.
                                </p>

                                <div className="text-button">
                                  <a
                                    href="https://forms.gle/dM18ytjpnvkYxYsT7"
                                    target="_blank"
                                  >
                                    Book Demo
                                  </a>
                                </div>
                              </div>
                            </>
                          </li>
                        )}
                        {tabIndex === 2 && (
                          <li className="tab-content active">
                            <>
                              <div className="left-image">
                                <img
                                  className="img-fluid"
                                  src={artificial}
                                  alt=""
                                  width="320px"
                                  height="320px"
                                />
                              </div>

                              <div className="right-content">
                                <h4>Automated Analysis</h4>
                                <p>
                                  After submitting the data, our advanced AI
                                  algorithms kick in. They automatically analyze
                                  the information, identifying patterns,
                                  anomalies and trends.
                                  <br /> <br /> This automated analysis not only
                                  saves time but also enhances accuracy by
                                  detecting inconsistencies or areas that
                                  require attention.
                                </p>
                              </div>
                            </>
                          </li>
                        )}

                        {tabIndex === 3 && (
                          <li className="tab-content active">
                            <>
                              <div className="left-image">
                                <img
                                  className="img-fluid"
                                  src={report}
                                  alt=""
                                  width="320px"
                                  height="320px"
                                />
                              </div>
                              <div className="right-content">
                                <h4>Data Visualization</h4>
                                <p>
                                  The analyzed data is then transformed into
                                  visually engaging charts, graphs, and numbers
                                  on the dashboard.
                                  <br />
                                  <br />
                                  This visualization provides an intuitive and
                                  comprehensive overview of a company's
                                  sustainability performance. Users can navigate
                                  through the dashboard to understand their
                                  environmental impact, track progress and
                                  identify areas for improvement.
                                </p>
                              </div>
                            </>
                          </li>
                        )}

                        {tabIndex === 4 && (
                          <li className="tab-content active">
                            <>
                              <div className="left-image">
                                <img
                                  className="img-fluid"
                                  src={info}
                                  alt=""
                                  width="320px"
                                  height="320px"
                                />
                              </div>
                              <div className="right-content">
                                <h4>Insightful Reporting</h4>
                                <p>
                                  GreenLead generates detailed sustainability
                                  reports based on the analyzed data.
                                  <br />
                                  <br />
                                  These reports offer insights, comparisons, and
                                  trends, empowering companies to make informed
                                  decisions aligned with their Environmental,
                                  Social, and Governance (ESG) goals. The
                                  reports can be easily shared with
                                  stakeholders, promoting transparency and
                                  accountability.
                                </p>

                                <div className="text-button">
                                  <a
                                    href="https://forms.gle/dM18ytjpnvkYxYsT7"
                                    target="_blank"
                                  >
                                    Book Demo
                                  </a>
                                </div>
                              </div>
                            </>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="hero" className="container col-xxl-12 mt-1 mb-2">
        <div className="row flex-lg-row-reverse align-items-center g-2 py-2">
          <div className="col-10 col-sm-8 col-lg-5">
            <img
              id="hero-img"
              src={dashboard1}
              className="d-block mx-lg-auto img-fluid"
              alt=""
              width="850"
              height="850"
              loading="lazy"
            />
          </div>
          <div className="col-lg-7 mt-2">
            <p id="esg">
              <i>
                We have revolutionized sustainability reporting with our ESG
                software. Explore our user-friendly dashboard featuring GRI
                Reporting templates, AI-enabled reports analysis and
                personalized insights. At GreenLead, we're committed to
                reshaping environmental and social responsibility through
                innovative and comprehensive digital solutions.
              </i>
            </p>
            <div className=" gap-2 d-md-flex justify-content-md-start mt-4">
              <Link className="btn btn-success px-4 me-md-2" to="/dashboard">
                Check it out
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
