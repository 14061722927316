import React from 'react'
import Navbar from '../components/Navbar'
import Hero from '../components/Home/Hero'
import Services from '../components/Home/Services'
import HowItWorks from '../components/Home/HowItWorks'
import Roots from '../components/Home/Roots'
// import ImpactScores from '../components/Home/ImpactScores'
import WhyGreenlead from '../components/Home/WhyGreenlead'
import EducationalInitiatives from '../components/Home/EducationalInitiatives'
import Contact from '../components/Contact'
import Footer from '../components/Footer'

const Home = () => {
  return (
    <>
    <Navbar/>
    <Hero/>
    <Services/>
    <HowItWorks/>
    <Roots/>
    {/* <ImpactScores/> */}
    <WhyGreenlead/>
    <EducationalInitiatives/>
    <Contact/>
    <Footer/>
    </>
  )
}

export default Home