import React from 'react';
import money12 from '../../assets/money12.jpg';

const WhyGreenlead = () => {
  return (
    <div className="container section-wrapper col-xxl-8">
      <div className="row flex-lg-row-reverse align-items-center g-4 py-4">
        <div id="reforest" className="col-lg-12">

          <h3 className="fw-bold lh-1 mb-3 text-center">
            Why GreenLead<span className="releaf"></span>
            <img src={money12} alt="" className="money-image" width="100" height="50" role="img" />
          </h3>

          <p>At GreenLead, we offer several compelling reasons why choosing our platform is your best path to environmental and social responsibility:</p>
          <ul className="list-unstyled text-center mt-5 greenlead">
            <li className="d-flex flex-column flex-md-row m-1">
              <i className="fa-regular fa-circle-check text-success fw-bold fs-3"></i>
              <b className="ms-2">Automated ESG Reporting :</b> Efficiently generate Environmental, Social, and Governance reports through automated processes.
            </li>
            <li className="d-flex flex-column flex-md-row m-1">
              <i className="fa-regular fa-circle-check text-success fw-bold fs-3"></i>
              <b className="ms-2">Transparent Data Visualization :</b> Clear, accessible representation of data for easy understanding and analysis.
            </li>
            <li className="d-flex flex-column flex-md-row m-1">
              <i className="fa-regular fa-circle-check text-success fw-bold fs-3"></i>
              <b className="ms-2">Open-Data Platform Fosters Accountability :</b> Transparent data-sharing enhances accountability.
            </li>
            <li className="d-flex flex-column flex-md-row m-1">
              <i className="fa-regular fa-circle-check text-success fw-bold fs-3"></i>
              <b className="ms-2">Enhanced CSR Analytics Integration :</b> Deepen CSR impact through advanced analytics and integrated reporting solutions.
            </li>
            <li className="d-flex flex-column flex-md-row m-1">
              <i className="fa-regular fa-circle-check text-success fw-bold fs-3"></i>
              <b className="ms-2">Resource-Saving Sustainability Practices :</b> Promote environmental responsibility, conserving resources for sustainability.
            </li>
          </ul>
        </div>

      </div>
    </div>
  );
};

export default WhyGreenlead;
