import React from 'react';
import chartImage from '../../assets/chart.jpg'; 
import esg10Image from '../../assets/esg10.jpg'; 
import trees7Image from '../../assets/trees7.jpg'; 
import { Link } from 'react-router-dom';
import './About.css'

const AboutHero = () => {
  return (
    <div>
      <section id="about-boxes" className="about-boxes"> 
        <div className="container" data-aos="fade-up"> 
          <div className="row">
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
              <div className="card">
                <img src={chartImage} className="card-img-top" alt="chart" /> 
                <div className="card-icon">
                  <i className='bx bxs-pin'></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title"><Link to="">Our Mission</Link></h5> 
                  <p className="card-text">
                    At GreenLead, our mission is to revolutionize sustainability reporting by providing a cutting-edge open-data platform.
                    We are dedicated to fostering transparency, automating ESG reporting and aiding businesses in achieving CSR goals. 
                    By simplifying and enhancing the reporting process, we strive to accelerate the global transition to sustainable 
                    practices, driving positive environmental impact and corporate responsibility.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
              <div className="card">
                <img src={esg10Image} className="card-img-top" alt="esg"/> 
                <div className="card-icon">
                  <i className='bx bxs-folder-open'></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title"><Link to="">Our Plan</Link></h5> 
                  <p className="card-text">
                    Our strategic plan involves revolutionizing sustainability reporting by automating processes, incorporating advanced analytics for trend analysis and predictive insights. 
                    This streamlined approach not only accelerates reporting but also simplifies the process, making it cost-effective.
                    It marks our commitment to fostering a sustainable future through informed, data-driven decision-making.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
              <div className="card">
                <img src={trees7Image} className="card-img-top" alt="trees"/> 
                <div className="card-icon">
                  <i className='bx bx-line-chart-down'></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title"><Link to="">Our Vision</Link></h5> 
                  <p className="card-text">
                    GreenLead envisions a transformative global landscape where businesses thrive sustainably. 
                    Through cutting-edge technology, we aspire to empower businesses to embrace transparency and accountability in their initiatives.
                    Our vision is a world where data-driven insights, innovation, and collaborative efforts lead to between economic prosperity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutHero;
