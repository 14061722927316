import React from "react";
import { Link } from "react-router-dom";
import Gl2Img from "../../assets/GL2.jpg";
import "./Dashboard.css";
import { useCookies } from "react-cookie";

const SidebarDashboard = ({ sidebar }) => {
  const [, , removeCookie] = useCookies(["g_accessToken", "username", "id"]);

  const handleLogout = () => {
    removeCookie("g_accessToken", { path: "/" });
    removeCookie("username", { path: "/" });
    removeCookie("id", { path: "/" });
    window.location.href = "/";
    return false;
  };

  // checking if a user is logged in
  const [cookies] = useCookies(["id"]);
  const isLoggedIn = cookies.id !== undefined;

  return (
    <div className={sidebar ? "sidebar active" : "sidebar"}>
      <div className="logo-details" id="greenwatch-logo">
        <img
          src={Gl2Img}
          alt=""
          className="bi me-2"
          width="45"
          height="45"
          role="img"
        />
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo_name fw-bold">GreenLead</span>
        </Link>
      </div>
      <ul className="nav-links">
        {/* <li>
          <Link to="/carbon-credits">
            <i className="bx bxs-dashboard"></i>
            <span className="links_name">Carbon Credits</span>
          </Link>
        </li> */}
        <li>
          <Link to="/esg">
            <i className="bx bxs-briefcase-alt-2"></i>
            <span className="links_name">ESG Reports</span>
          </Link>
        </li>
        <li>
          <Link to="/carbon-projects">
            <i className="bx bxs-edit-alt"></i>
            <span className="links_name">Carbon Projects</span>
          </Link>
        </li>
        {/* <li>
          <Link to="/main">
            <i className="bx bxs-analyse"></i>
            <span className="links_name">Company Profiles</span>
          </Link>
        </li> */}
        {/* <li>
          <Link to="#">
            <i className="bx bxs-bar-chart-alt-2"></i>
            <span className="links_name">
              Enhanced Data <br /> Analytics
            </span>
          </Link>
        </li> */}

        {/* show settings if a user is logged in */}
        {isLoggedIn ? (
          <>
            <li>
              <Link to="/settings">
                <i className="bx bxs-pie-chart-alt-2"></i>
                <span className="links_name">Settings</span>
              </Link>
            </li>
            <li>
              <Link to="#">
                <i className="bx bx-log-out fw-bold fs-3"></i>
                <span className="links_name fw-bold" onClick={handleLogout}>
                  Logout
                </span>
              </Link>
            </li>
          </>
        ) : null}
      </ul>
    </div>
  );
};

export default SidebarDashboard;
