import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Dashboard.css';
import TopNavbar from './TopNavbar';
import SidebarDashboard from './SidebarDashboard';

const CarbonCredits = () => {
  const [sidebar, setSidebar] = useState(false);

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  return (
    <div>
      {/* Sidebar Dashboard */}
      <SidebarDashboard sidebar={sidebar} />
      <section className="home-section">
        {/* Dashboard Navbar */}
        <TopNavbar toggleSidebar={toggleSidebar} />

        {/* Body content */}
        <div className="home-content mb-4">
          <div className="sales-boxes responsive">
            <div className="recent-sales box">
              <div className="title">Auto-generated Carbon Credits</div>

              <div className="container mt-3">
                <div className="row">
                  <div className="col-md-8 d-flex justify-content-between">
                    <div className="col-md-3">
                      <Link to="#" className="btn btn-sm btn-success btn-rounded">Export CSV</Link>
                    </div>
                    <div className="col-md-3">
                      <Link to="#" className="btn btn-sm btn-danger btn-rounded">Export PDF</Link>
                    </div>
                    <div className="col-md-3">
                      <button type="button" className="btn btn-primary mb-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        Demo
                      </button>

                      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1 className="modal-title fs-5 fw-bold" id="staticBackdropLabel">Premium Subscriptions</h1>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              <ul>
                                <li>Download data in CSV and PDF formats</li>
                                <li>Enhanced Analytics using our machine learning models</li>
                                <li>Compare with industry players</li>
                                <li>Consultation Services</li>
                              </ul>
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-sm btn-secondary" data-bs-dismiss="modal">Close</button>
                              <Link to="https://forms.gle/XpU1LomwpAjQWTNE8" target="_blank" className="btn btn-sm btn-success btn-rounded">Book Demo</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div id="search-input" className="form-group mb-3">
                      <input type="text" id="searchField" className="form-control" placeholder="Search" />
                    </div>
                  </div>
                </div>

                <h5 className="fw-bold">Company X.</h5>
                <div className="overview-boxes">
                  <div className="box">
                    <div className="right-side">
                      <div className="box-topic">Distance Covered</div>
                      <div className="d-flex">
                        <div className="read fw-bold fs-4 mt-2 text-muted"> </div><span className="m-2 fs-5 fw-bold text-muted ">Km</span>
                      </div>
                      <div className="box-topic">Vehicles</div>
                      <div className="d-flex">
                        <div id="read" className="fw-bold fs-4 mt-2 text-muted"> </div><span className="m-2 fs-5 fw-bold text-muted "></span>
                      </div>
                    </div>
                    <i id="check" className='bx bxs-car'></i>
                  </div>

                  <div className="box">
                    <div className="right-side">
                      <div className="box-topic">Carbon Emission Avoided</div>
                      <div className="d-flex">
                        <div className="carbon fw-bold fs-4 mt-2 text-danger"></div><span className="fw-bold text-danger " style={{ fontSize: '12px', marginTop: '8%', marginLeft: '3%' }}>Metric tonnes</span>
                      </div>
                    </div>
                    <i id="check" className='bx bx-recycle fw-bold'></i>
                  </div>

                  <div className="box">
                    <div className="right-side">
                      <div className="box-topic text-start">Value of Carbon Credits generated</div>
                      <div className="d-flex">
                        <span className="m-1 fs-3 fw-bold text-success ">$</span><div className="fw-bold fs-4 mt-2 text-success credit"></div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <canvas id="demo2Chart" width="600" height="200"></canvas> */}

                <h5 className="fw-bold mt-3">Company Y</h5>
                <div className="overview-boxes">
                  <div className="box">
                    <div className="right-side">
                      <div className="box-topic">Distance Covered</div>
                      <div className="d-flex">
                        <div id="read" className="fw-bold fs-4 mt-2 text-muted"> </div><span className="m-2 fs-5 fw-bold text-muted "></span>
                      </div>
                    </div>
                    <i id="check" className='bx bxs-car'></i>
                  </div>

                  <div className="box">
                    <div className="right-side">
                      <div className="box-topic">Carbon Emission Avoided</div>
                      <div className="d-flex">
                        <div style={{ display: 'none' }} id="carbon" className="fw-bold fs-4 mt-2 text-danger"></div><span className=" fw-bold text-danger " style={{ fontSize: '13px', marginTop: '8%', marginLeft: '3%', display: 'none' }}>Metric tonnes</span>
                      </div>
                    </div>
                    <i id="check" className='bx bx-recycle fw-bold'></i>
                  </div>

                  <div className="box">
                    <div className="right-side">
                      <div className="box-topic text-start">Carbon Credits generated</div>
                      <div className="d-flex">
                        <span style={{ display: 'none' }} className="m-1 fs-3 fw-bold text-success ">$</span><div style={{ display: 'none' }} id="credit" className="fw-bold fs-4 mt-2 text-success credit"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row paginator-container">
                  <div id="pagination" className="col-md-8 fw-bold">
                    Showing page 1 of 10
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CarbonCredits;
