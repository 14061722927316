import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const COLORS = [
  "rgba(75,192,192,0.2)",
  "rgba(255,99,132,0.2)",
  "rgba(255,206,86,0.2)",
  "rgba(54,162,235,0.2)",
  "rgba(153,102,255,0.2)",
];

const EmployeeBar = ({ data }) => {
  const chartData = [
    { name: "Age:20-30", employees: data.total_number_of_employees_20_30 },
    { name: "Age:31-40", employees: data.total_number_of_employees_31_40 },
    { name: "Age:41-50", employees: data.total_number_of_employees_41_50 },
    { name: "Age:51-60", employees: data.total_number_of_employees_51_60 },
    { name: "Age:61+", employees: data.total_number_of_employees_61 },
  ];

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: "1rem",
        boxShadow: "0.5px 1px 3px 2px rgba(80, 165, 98, 0.5)",
        borderRadius: "0.5rem",
      }}
    >
      <h6
        style={{
          color: "#717171",
          fontSize: "12px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Employee Age Distribution
      </h6>
      <ResponsiveContainer width="100%" height="80%">
        <BarChart
          data={chartData}
          margin={{ top: 5, right: 30, left: 20, bottom: 40 }} // Increased bottom margin
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            angle={-45}
            textAnchor="end"
            tick={{ fontSize: 12 }}
          />
          <YAxis />
          <Tooltip />
          <Legend
            verticalAlign="top"
            align="left"
            wrapperStyle={{ fontSize: "10px", paddingBottom: '20px' }} // Added padding to the top
          />
          <Bar dataKey="employees" fill="#00C49F" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default EmployeeBar;
