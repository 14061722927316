import React from 'react'

const Employment = ({ formData7, setFormData7, handleInputChange }) => {
  return (
    <div>
      <h2 className='fw-bold'>GRI 401: Employment</h2>
      
      <div>
        <div className="mb-4 d-flex">
          <label htmlFor="total_number_of_employees" className="me-2 mb-2 flex-1">(GRI 401-1): Number of Employees </label>
          <input type="number" className='custom-input flex-1' id="total_number_of_employees" name="total_number_of_employees" value={formData7.total_number_of_employees} onChange={(e) => handleInputChange(e, setFormData7)} />
        </div>

        <div className="mb-4 d-flex">
          <label htmlFor="total_number_of_employees_20_30" className="me-2 mb-2 flex-1">Number of employees (20-30 years)</label>
          <input type="number" className='custom-input flex-1' id="total_number_of_employees_20_30" name="total_number_of_employees_20_30" value={formData7.total_number_of_employees_20_30} onChange={(e) => handleInputChange(e, setFormData7)}/>
        </div>

        <div className="mb-4 d-flex">
          <label htmlFor="total_number_of_employees_31_40" className="me-2 mb-2 flex-1">Number of employees (31-40 years)</label>
          <input type="number" className='custom-input flex-1' id="total_number_of_employees_31_40" name="total_number_of_employees_31_40" value={formData7.total_number_of_employees_31_40} onChange={(e) => handleInputChange(e, setFormData7)} />
        </div>

        <div className="mb-4 d-flex">
          <label htmlFor="total_number_of_employees_41_50" className="me-2 mb-2 flex-1">Number of employees (41-50 years)</label>
          <input type="number" className='custom-input flex-1' id="total_number_of_employees_41_50" name="total_number_of_employees_41_50" value={formData7.total_number_of_employees_41_50} onChange={(e) => handleInputChange(e, setFormData7)}/>
        </div>

        <div className="mb-4 d-flex">
          <label htmlFor="total_number_of_employees_51_60" className="me-2 mb-2 flex-1">Number of employees (51-60 years)</label>
          <input type="number" className='custom-input flex-1' id="total_number_of_employees_51_60" name="total_number_of_employees_51_60" value={formData7.total_number_of_employees_51_60} onChange={(e) => handleInputChange(e, setFormData7)}/>
        </div>

        <div className="mb-4 d-flex">
          <label htmlFor="total_number_of_employees_61" className="me-2 mb-2 flex-1">Number of employees (61+ years)</label>
          <input type="number" className='custom-input flex-1' id="total_number_of_employees_61" name="total_number_of_employees_61" value={formData7.total_number_of_employees_61} onChange={(e) => handleInputChange(e, setFormData7)}/>
        </div>
      </div>
    </div>
  )
}

export default Employment