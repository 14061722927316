import React from 'react';

const Introduction = () => {
  return (
    <div>
      <div id="section1" className="section">
        <h2 className='fw-bold'>Introduction</h2>
        <p className='m-2'>Welcome to the Greenlead ESG Reporting Form! This platform is designed to streamline your Environmental and Social reporting process, helping you gather and analyze data efficiently in alignment with Global Reporting Initiative (GRI) standards.</p>
        
        <div className='m-2'>
        <h3 className='mt-3 fw-bold'>How it Works</h3>
        <div className='d-flex mb-2'>
          <h4 className='flex-1'>Introduction:</h4>
          <p className='flex-1'>This section provides an overview of the reporting form and its purpose</p>
        </div>
        <div className='d-flex mb-2'>
          <h4 className='flex-1'>Instructions:</h4>
          <p className='flex-1'>Input data for each ESG aspect, categorized according to GRI codes, ensuring comprehensive reporting.</p>
        </div>
        <div className='d-flex mb-2'>
          <h4 className='flex-1'>Review and Submission:</h4>
          <p className='flex-1'>Review your entries before final submission to ensure accuracy and completeness.</p>
        </div>
        </div>
        <p>Thank you for choosing Greenlead for your ESG reporting needs. Together, let's drive positive change and build a more sustainable future!</p>
      </div>
    </div> 
  );
}

export default Introduction;


