import React from 'react'

const OccupationalHealth = ({ formData10, setFormData10, handleInputChange }) => {
  return (
    <div>
      <h2 className='fw-bold'>GRI 403: Occupational Health and Safety:</h2>
      
      <div>
        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="total_number_occupational_accidents" className="me-2 mb-2 flex-1">(GRI 403-1): Occupational Accidents</label>
          <input type="number" className='custom-input flex-1' id="total_number_occupational_accidents" name="total_number_occupational_accidents" value={formData10.total_number_occupational_accidents} onChange={(e) => handleInputChange(e, setFormData10)}/>
        </div>

        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="total_number_occupational_diseases" className="me-2 mb-2 flex-1">(GRI 403-2): Occupational Diseases </label>
          <input type="number" className='custom-input flex-1' id="total_number_occupational_diseases" name="total_number_occupational_diseases" value={formData10.total_number_occupational_diseases} onChange={(e) => handleInputChange(e, setFormData10)}/>
        </div>

        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="list_health_safety_training_programs" className="me-2 mb-2 flex-1">(GRI 403-3): Health and Safety Training</label>
          <textarea rows="3" className='custom-input flex-1' id="list_health_safety_training_programs" name="list_health_safety_training_programs" value={formData10.list_health_safety_training_programs} onChange={(e) => handleInputChange(e, setFormData10)}></textarea>
        </div>
        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="emergency_response_plans_procedures" className="me-2 mb-2 flex-1">(GRI 403-4): Emergency Preparedness</label>
          <textarea rows="3" className='custom-input flex-1' id="emergency_response_plans_procedures" name="emergency_response_plans_procedures" value={formData10.emergency_response_plans_procedures} onChange={(e) => handleInputChange(e, setFormData10)}></textarea>
        </div>
      </div>
    </div>
  )
}

export default OccupationalHealth