import React from "react";
import { UserDropdown } from "../EnvironmentalMultiform";

const Energy = ({
  formData2,
  setFormData2,
  handleInputChange,
  renewableOptions,
}) => {
  return (
    <div>
      <h2 className="fw-bold">GRI 302: Energy</h2>
      <p className="mt-2 m-2">
        GRI 302-1: Energy Consumption within the Organization{" "}
      </p>
      <div className="m-3">
        <div className="d-flex align-items-center mb-5">
          <label htmlFor="energy1-type" className="me-2 label-font">
            Form of energy 1 (Renewable/Non-Renewable)
          </label>
          <UserDropdown
            options={renewableOptions}
            value={formData2.energy_source1}
            onChange={(e) => handleInputChange(e, setFormData2)}
            name="energy_source1"
          />
        </div>

        <div className="mb-5 mt-3 d-flex">
          <label htmlFor="energy1-amount" className="me-2 flex-1">
            Amount (Kwh):
          </label>
          <input
            type="number"
            className="custom-input flex-1"
            id="energy1-amount"
            name="amount1_energy"
            value={formData2.amount1_energy}
            onChange={(e) => handleInputChange(e, setFormData2)}
          />
        </div>

        <div className="mb-5 d-flex">
          <label htmlFor="energy1-sources" className="me-2 flex-1">
            List of the sources:
          </label>
          <textarea
            className="custom-input flex-1"
            id="energy1-sources"
            name="source1_energy"
            rows="3"
            value={formData2.source1_energy}
            onChange={(e) => handleInputChange(e, setFormData2)}
          ></textarea>
        </div>

        <div className="d-flex mt-5 mb-5">
          <label htmlFor="energy2-type" className="me-2 label-font">
            Form of energy 2 (Renewable/Non-Renewable)
          </label>
          <UserDropdown
            options={renewableOptions}
            value={formData2.energy_source2}
            onChange={(e) => handleInputChange(e, setFormData2)}
            name="energy_source2"
          />
        </div>

        <div className="mb-5 mt-3 d-flex">
          <label htmlFor="energy2-amount" className="me-2 flex-1">
            Amount (Kwh):
          </label>
          <input
            type="number"
            className="custom-input flex-1"
            id="energy2-amount"
            name="amount2_energy"
            value={formData2.amount2_energy}
            onChange={(e) => handleInputChange(e, setFormData2)}
          />
        </div>

        <div className="mb-5 d-flex">
          <label htmlFor="energy2-sources" className="me-2 flex-1">
            List of the sources:
          </label>
          <textarea
            className="custom-input flex-1"
            id="energy2-sources"
            name="source2_energy"
            rows="3"
            value={formData2.source2_energy}
            onChange={(e) => handleInputChange(e, setFormData2)}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default Energy;
