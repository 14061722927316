import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

const TopNavbar = ({ toggleSidebar }) => {
  const [username, setUsername] = useState("");
  const [cookies] = useCookies(["username"]);

  useEffect(() => {
    setUsername(cookies.username);
    // console.log("Username:", username); // Log the username
    // console.log("Cookies:", cookies); // Log the cookies
  }, [cookies.username, setUsername]);

  return (
    <nav className="d-flex justify-content-between">
      <div className="sidebar-button" onClick={toggleSidebar}>
        <i className="bx bx-menu"></i>
        <p className="dashboard">Dashboard</p>
        </div>
        <div>{username && <p>Welcome {username}</p>}</div>
    </nav>
  );
};

export default TopNavbar;
