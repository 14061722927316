import React from 'react'

const TrainingEducation = ({ formData11, setFormData11, handleInputChange }) => {
  return (
    <div>
      <h2 className='fw-bold'>GRI 404: Training and Education:</h2>
      
      <div>
        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="list_employee_training_programs" className="me-2 mb-2 flex-1">(GRI 404-1): Training Programs</label>
          <textarea rows="3"  className='custom-input flex-1' id="list_employee_training_programs" name="list_employee_training_programs" value={formData11.list_employee_training_programs} onChange={(e) => handleInputChange(e, setFormData11)}></textarea>
        </div>

        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="list_education_finance_assistance_provided" className="me-2 mb-2 flex-1">(GRI 404-2): Education Assistance </label>
          <textarea rows="3" className='custom-input flex-1' id="list_education_finance_assistance_provided" name="list_education_finance_assistance_provided" value={formData11.list_education_finance_assistance_provided} onChange={(e) => handleInputChange(e, setFormData11)}></textarea>
        </div>

        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="list_opportunities_career_advancement" className="me-2 mb-2 flex-1">(GRI 404-3): Employee Development Opportunities </label>
          <textarea rows="3" className='custom-input flex-1' id="list_opportunities_career_advancement" name="list_opportunities_career_advancement" value={formData11.list_opportunities_career_advancement} onChange={(e) => handleInputChange(e, setFormData11)}></textarea>
        </div>
      </div>
    </div>
  )
}

export default TrainingEducation