import React from 'react';
import microsoftBadge from "../assets/MS_Startups_Celebration_Badge_Dark.png";

const Footer = () => {
  return (
    <div className="container">
      <h2 className="pb-2 border-bottom fw-bold">Partners</h2>
      <footer className="py-1">
        <div className="row">
          <div className="col-md-6 mb-3 ">
            <img src={microsoftBadge} alt="" className="img-fluid bi me-2" role="img" width="300px" height="170px" />
          </div>

          <div className="col-md-6 mb-3">
            <form>
              <h5>Subscribe to our newsletter</h5>
              <p>Monthly digest of what's new and exciting from us.</p>
              <div className="d-flex flex-column flex-sm-row gap-2">
                <label htmlFor="newsletter1" className="visually-hidden">Email address</label>
                <input id="newsletter1" type="text" className="form-control" placeholder="Email address" />
                <button id="subscribe" className="btn btn-success" type="button">Subscribe</button>
              </div>
            </form>
          </div>

        </div>

        <div className="d-flex flex-column flex-md-row justify-content-between py-2 my-2 border-top">
          <p>&copy; 2023 GreenLead. All rights reserved.</p>
          <ul className="list-unstyled d-flex">
            <li className="ms-3"><a className="link-body-emphasis" href="#"></a></li>
            <li className="ms-3"><a className="link-body-emphasis" href="#"></a></li>
            <li className="ms-3"><a className="link-body-emphasis" href="#"></a></li>
          </ul>
        </div>
      </footer>
    </div>
  );
}

export default Footer;

