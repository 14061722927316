import React from 'react'
import './CommonComponents.css';

const Contact = () => {
  return (
    <>
    <div className="container">
      <h2  className="pb-2 border-bottom fw-bold">Contact <span className="service-heading">Us</span></h2>
    </div>

    <div className="container contact-container mt-3 mb-5">
      
      <div className="content">
        <div className="left-side">
          <div className="address details">
          <i className="fa-solid fa-location-dot"></i>
            <div className="topic">Address</div>
            <div className="text-one">Nairobi, Kenya</div>
          </div>
          <div className="phone details">
            <i className="fa-solid fa-phone"></i>
            <div className="topic">Phone</div>
            <div className="text-one">+254 718074023</div>
          </div>
          <div className="email details"> 
            <i className="fa-solid fa-envelope"></i>
            <div className="topic">Email</div>
            <div className="text-one">info@greenleadafrica.com </div>
          </div>
          <div className="phone details">
            <i className="fa-brands fa-linkedin-in"></i>
            <div className="topic">LinkedIn</div>
            <div className="text-one"><a className="fw-bold" href="https://www.linkedin.com/company/greenlead-africa/">Follow Us</a></div>
          </div>
        </div>
        
      </div>
    </div>
    </>

  )
}

export default Contact