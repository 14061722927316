import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  PieChart,
  DoughnutChart,
  PolarAreaChart,
  VerticalBarChart,
  WasteChart,
} from "./EnvironmentalCharts";
import { EmployeeBar, GenderPiechart } from "./SocialCharts";
import { useCookies } from "react-cookie";
import dayjs from "dayjs";
import Loader from "../../Loader";
import Config from "../../../config/Config";

const ShowCharts = () => {
  const showChartsRef = useRef(null);

  const [companyName, setCompanyName] = useState("");
  const [companyDate, setCompanyDate] = useState("");

  const [lastEnvItemId, setlastEnvItemId] = useState(null);
  const [lastSocialItemId, setLastSocialItemId] = useState(null);

  const [cookies] = useCookies(["g_accessToken", "id", "companyId"]);

  const [loading, setLoading] = useState(true);

//   const handlePdfGeneration = async () => {
//     try {
//         // Make requests to get the PDF URLs
//         const envResponse = await axios.get(
//             `${Config.apiBaseUrl}/v1/environmental_download_pdf/${lastEnvItemId}/`
//         );
//
//         // Get the PDF URL from the response
//         const envPdfUrl = envResponse.data.download_url;
//         console.log(envPdfUrl)
//
//         // Create a hidden anchor element
//         const link = document.createElement('a');
//         link.href = envPdfUrl;
//         link.download = 'file.pdf'; // Set the desired filename
//
//         // Trigger a click event to start the download
//         link.dispatchEvent(new MouseEvent('click'));
//
//     } catch (error) {
//         console.error("Error fetching PDF:", error);
//     }
// };

  const handlePdfGeneration = async () => {
    try {
      // Construct the URL for PDF generation
      const pdfUrl = `${Config.apiBaseUrl}/v1/environmental_download_pdf/${lastEnvItemId}/`;
      // const pdfUrl = `${Config.apiBaseUrl}/v1/environmental_download_pdf/2/`;

      // Open the URL in a new tab
      window.open(pdfUrl, '_blank');
    } catch (error) {
      console.error("Error opening PDF:", error);
    }
  };


  // get the company name
  useEffect(() => {
    const fetchUserProfileData = async () => {
      try {
        const token = cookies.g_accessToken;
        const companyId = cookies.companyId;

        // token exists
        if (token) {
          // Fetch the company data using the company ID
          const companyResponse = await axios.get(
            `${Config.apiBaseUrl}/v1/companies/${companyId}/`
          );
          const companyData = companyResponse.data;

          // Set the company name in state
          setCompanyName(companyData.name);

          const date = dayjs(companyData.created_at);

          // Format the date to display only the month and year
          const formattedDate = date.format("MMMM YYYY");

          setCompanyDate(formattedDate);
        }
      } catch (error) {
        console.error("Error fetching user profile or company data:", error);
      }
    };

    fetchUserProfileData();
  }, [cookies.companyId, cookies.g_accessToken]);

  // environmental chart data
  const [energyChartData, setEnergyChartData] = useState(null);
  const [waterChartData, setWaterChartData] = useState(null);
  const [biodiversityChartData, setBiodiversityChartData] = useState(null);
  const [emissionsChartData, setEmissionsChartData] = useState(null);
  const [effluentsChartData, setEffluentsChartData] = useState(null);

  // Social charts data
  const [employeeChartData, setEmployeeChartData] = useState(null);
  const [genderChartData, setGenderChartData] = useState(null);

  // fetch the environmental and social data for a particular company
  useEffect(() => {
    const fetchEnvData = async () => {
      try {
        const companyId = cookies.companyId;
        const token = cookies.g_accessToken;

        // token exists
        if (token) {
          const response =
            await axios.get(`${Config.apiBaseUrl}/v1/companies/${companyId}/environmental/
          `);
          const responseData = response.data;
          // Get the last item from responseData
          const lastItems = responseData[responseData.length - 1];

          // Store the id in state
          setlastEnvItemId(lastItems.id);

          // environmental chart data
          const energyData = generateEnergyData(lastItems);
          const waterData = generateWaterData(lastItems);
          const biodiversityData = generateBiodiversityData(lastItems);
          const emissionsData = generateEmissionsData(lastItems);
          const effluentsData = generateEffluentsData(lastItems);

          // environmental chart data
          setEnergyChartData(energyData);
          setWaterChartData(waterData);
          setBiodiversityChartData(biodiversityData);
          setEmissionsChartData(emissionsData);
          setEffluentsChartData(effluentsData);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchSocialData = async () => {
      try {
        const companyId = cookies.companyId;
        const token = cookies.g_accessToken;

        // token exists
        if (token) {
          const response = await axios.get(
            `${Config.apiBaseUrl}/companies/${companyId}/social/`
          );
          const responseData = response.data;

          // Get the last item from responseData
          const lastSocialItems = responseData[responseData.length - 1];

          // Store the id in state
          setLastSocialItemId(lastSocialItems.id);

          // social chart data
          const employeeData = generateEmployeeData(lastSocialItems);
          const genderData = generateGenderData(lastSocialItems);

          // social chart data
          setEmployeeChartData(employeeData);
          setGenderChartData(genderData);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchEnvData();
    fetchSocialData();
  }, []);

  // Function to generate energy chart data
  const generateEnergyData = (formData) => {
    return {
      energy_source1: formData.energy_source1,
      energy_source2: formData.energy_source2,
      amount1_energy:
        formData.energy_source1 === "Renewable" ? formData.amount1_energy : 0,
      amount2_energy:
        formData.energy_source2 === "Non-renewable"
          ? formData.amount2_energy
          : 0,
    };
  };

  const generateWaterData = (formData) => {
    return {
      water_source1: formData.water_source1,
      water_source2: formData.water_source2,
      amount1_water: formData.amount1_water,
      amount2_water: formData.amount2_water,
    };
  };

  const generateBiodiversityData = (formData) => {
    return {
      operational_sites: formData.operational_sites,
      adjacent_operational_sites: formData.adjacent_operational_sites,
      sites_outside_protected_areas: formData.sites_outside_protected_areas,
      sites_located_in_high_biodiversity_areas:
        formData.sites_located_in_high_biodiversity_areas,
    };
  };

  const generateEmissionsData = (formData) => {
    return {
      direct_emission: formData.direct_emission,
      energy_indirect_emission: formData.energy_indirect_emission,
      other_indirect_emission: formData.other_indirect_emission,
    };
  };

  const generateEffluentsData = (formData) => {
    return {
      waste1: formData.waste1,
      waste2: formData.waste2,
      waste3: formData.waste3,
      amount1_waste: formData.amount1_waste,
      amount2_waste: formData.amount2_waste,
      amount3_waste: formData.amount3_waste,
    };
  };

  const generateEmployeeData = (formData) => {
    return {
      total_number_of_employees_20_30: formData.total_number_of_employees_20_30,
      total_number_of_employees_31_40: formData.total_number_of_employees_31_40,
      total_number_of_employees_41_50: formData.total_number_of_employees_41_50,
      total_number_of_employees_51_60: formData.total_number_of_employees_51_60,
      total_number_of_employees_61: formData.total_number_of_employees_61,
    };
  };

  const generateGenderData = (formData) => {
    return {
      total_number_of_male_employees: formData.total_number_of_male_employees,
      total_number_of_female_employees:
        formData.total_number_of_female_employees,
    };
  };

  // Function to render charts based on chart data
  const renderCharts = () => {
    if (
      energyChartData &&
      waterChartData &&
      biodiversityChartData &&
      emissionsChartData &&
      effluentsChartData &&
      employeeChartData &&
      genderChartData
    ) {
      return (
        <div className="container" ref={showChartsRef}>
          <div className="row ">
            <div className="col-lg-6">
              <div className="row">
                <div
                  className="col-md-6 mb-4"
                  style={{ width: "400px", height: "400px" }}
                >
                  <PieChart data={energyChartData} />
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-6 mb-4"
                  style={{ width: "400px", height: "400px" }}
                >
                  <DoughnutChart data={waterChartData} />
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-6 mb-4"
                  style={{ width: "400px", height: "400px" }}
                >
                  <PolarAreaChart data={biodiversityChartData} />
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-6 mb-4"
                  style={{ width: "400px", height: "400px" }}
                >
                  <VerticalBarChart data={emissionsChartData} />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              {/* Employee and Gender Charts */}
              <div className="" style={{ width: "400px", height: "400px" }}>
                <EmployeeBar data={employeeChartData} />
              </div>
              <div className="" style={{ width: "400px", height: "400px" }}>
                <GenderPiechart data={genderChartData} />
              </div>
              <div style={{ width: "400px", height: "400px" }}>
                <WasteChart data={effluentsChartData} />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      {/* show the company name here  and date here*/}
      <h4 style={{ margin: "2rem 0" }}>
        {companyName} ESG Reporting on {companyDate}
      </h4>
      {loading ? <Loader /> : <div>{renderCharts()}</div>}

      <button
        className="btn btn-success"
        style={{ marginTop: "6rem", marginLeft: "1.25rem" }}
        onClick={handlePdfGeneration}
      >
        Export PDF
      </button>
    </div>
  );
};

export default ShowCharts;
