import React from "react";
import { UserDropdown } from "../EnvironmentalMultiform";

const EffluentsWaste = ({
  formData6,
  setFormData6,
  handleInputChange,
  wasteTypeOptions,
  disposalMethodOptions,
}) => {
  return (
    <div>
      <h2 className="fw-bold mb-4">GRI 306: Effluents and Waste</h2>
      <div>
        <h4 className="mb-4 m-2" htmlFor="">
          GRI 306-1: Water discharge:
        </h4>
      </div>
      <div className="m-3">
        <h4>GRI 306-2: Waste by type and disposal method:</h4>
        <div className="mb-3">
          <div className="d-flex mb-4 align-items-center">
            <label htmlFor="" className="me-2 label-font flex-1">
              Type of waste 1: (hazardous waste, non-hazardous waste, and
              recycled waste.)
            </label>
            <UserDropdown
              options={wasteTypeOptions}
              value={formData6.waste1}
              onChange={(e) => handleInputChange(e, setFormData6)}
              name="waste1"
            />
          </div>

          <div className="mt-4 mb-4 d-flex">
            <label htmlFor="" className="me-2 flex-1">
              Amount (metric tonnes):{" "}
            </label>
            <input
              type="number"
              className="custom-input flex-1"
              value={formData6.amount1_waste}
              onChange={(e) => handleInputChange(e, setFormData6)}
              name="amount1_waste"
            />
          </div>
          <div className="mb-4 d-flex align-items-center">
            <label htmlFor="" className="me-2 flex-1">
              Disposal method: (landfill, incineration, recycling or treatment.)
            </label>
            <UserDropdown
              options={disposalMethodOptions}
              value={formData6.disposal1_waste}
              onChange={(e) => handleInputChange(e, setFormData6)}
              name="disposal1_waste"
            />
          </div>
        </div>

        <div className="mb-3">
          <div className="d-flex align-items-center">
            <label htmlFor="" className="me-2 label-font flex-1">
              Type of waste 2: (hazardous waste, non-hazardous waste, and
              recycled waste.)
            </label>
            <UserDropdown
              options={wasteTypeOptions}
              value={formData6.waste2}
              onChange={(e) => handleInputChange(e, setFormData6)}
              name="waste2"
            />
          </div>

          <div className="mt-4 mb-3 d-flex">
            <label htmlFor="" className="me-2 flex-1">
              Amount (metric tonnes):
            </label>
            <input
              type="number"
              className="custom-input flex-1"
              value={formData6.amount2_waste}
              onChange={(e) => handleInputChange(e, setFormData6)}
              name="amount2_waste"
            />
          </div>

          <div className="mb-4 d-flex align-items-center">
            <label htmlFor="" className="me-2 flex-1">
              Disposal method: (landfill, incineration, recycling or treatment.)
            </label>
            <UserDropdown
              options={disposalMethodOptions}
              value={formData6.disposal2_waste}
              onChange={(e) => handleInputChange(e, setFormData6)}
              name="disposal2_waste"
            />
          </div>
        </div>

        <div className="mb-3">
          <div className="d-flex align-items-center">
            <label htmlFor="" className="me-2 label-font flex-1">
              Type of waste 3: (hazardous waste, non-hazardous waste, and
              recycled waste.)
            </label>
            <UserDropdown
              options={wasteTypeOptions}
              value={formData6.waste3}
              onChange={(e) => handleInputChange(e, setFormData6)}
              name="waste3"
            />
          </div>
          <div className="mt-3 mb-3 d-flex">
            <label htmlFor="" className="me-2 flex-1">
              Amount (metric tonnes):
            </label>
            <input
              type="number"
              className="custom-input flex-1"
              value={formData6.amount3_waste}
              onChange={(e) => handleInputChange(e, setFormData6)}
              name="amount3_waste"
            />
          </div>
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="" className="me-2 flex-1">
              Disposal method: (landfill, incineration, recycling or treatment.)
            </label>
            <UserDropdown
              options={disposalMethodOptions}
              value={formData6.disposal3_waste}
              onChange={(e) => handleInputChange(e, setFormData6)}
              name="disposal3_waste"
            />
          </div>
        </div>

        <div className="d-flex">
          <h4 className="mb-3 flex-1">
            GRI 306-4: Transport of hazardous waste:
          </h4>
          <textarea
            id=""
            rows="3"
            value={formData6.transportation_of_waste}
            onChange={(e) => handleInputChange(e, setFormData6)}
            name="transportation_of_waste"
            className="custom-input flex-1"
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default EffluentsWaste;
