import React from 'react';

const Roots = () => {
  return (
    <div className="container mt-5" id="approach">
      <div className='container'>
        <h2 className="pb-2 border-bottom fw-bold">Our Routes to <span className="service-heading">Sustainability</span></h2>
        <div className="row g-2 py-3 roots">

          <div className="col-lg-6 col-md-6 col-sm-12 align-items-start">
            <div>
              <h3 className="fs-5 fw-bold">Transparency</h3>
              <p>GreenLead promotes transparency
through streamlined ESG reporting by utilizing an open-data platform. In an
era prone to greenwashing, we ensure authenticity and clarity, helping
businesses build trust and credibility.
              </p>                 
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 align-items-start">
            <div>
              <h3 className="fs-5 fw-bold">Accountability</h3>
              <p>
                GreenLead upholds accountability by
meticulously verifying ESG data on our platform. We ensure the accuracy
and reliability of the reported information, enabling businesses to make
impactful choices for responsibility and demonstrate their commitment to
sustainability.
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Roots;
