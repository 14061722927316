import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import solarImg from '../../assets/solar.jpg';
import windImg from '../../assets/wind.jpg';
import carsImg from '../../assets/cars.jpg';
import './Dashboard.css';
import TopNavbar from './TopNavbar';
import SidebarDashboard from './SidebarDashboard';

const CarbonProjects = () => {
  const [sidebar, setSidebar] = useState(false);

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };


  return (
    <div>
      {/* Sidebar Dashboard */}
      <SidebarDashboard sidebar={sidebar} />
      <section className="home-section">
        {/* Dashboard Navbar */}
        <TopNavbar toggleSidebar={toggleSidebar} />

        {/* Body content */}
        <div className="home-content mb-4">
          <div className="sales-boxes responsive">
            <div className="recent-sales box">
              <div className="container mt-3">
                <div className="row">
                  <div id="export-csv" className="col-md-8 d-flex justify-content-between">
                    <div className="col-md-6">
                      <Link to="#" className="btn btn-sm btn-danger btn-rounded">Download PDF</Link>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div id="search-input" className="form-group mb-3 ">
                      <input type="text" id="searchField" className="form-control" placeholder="Search" />
                    </div>
                  </div>
                </div>

                <h5 className="fw-bold mb-3">Companies and Organizations that have invested in Carbon projects</h5>
                <div className="overview-boxes">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="col">
                        <div id="carbon-project-card" className="card shadow-sm">
                          <img src={solarImg} className="img-fluid" alt="solar" />
                          <div className="card-body">
                            <p className="card-text"><b>Company X</b> leads the way, investing significantly in sustainable solar energy initiatives for a greener future.</p>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="btn-group">
                                <button type="button" className="btn btn-sm btn-primary">View Details</button>
                              </div>
                              <small className="text-muted">10/10/2023</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="col">
                        <div id="carbon-project-card" className="card shadow-sm">
                          <img src={windImg} className="img-fluid" alt="wind" />
                          <div className="card-body">
                            <p className="card-text"><b>Company Y</b> pioneers renewable energy, investing in wind power for a sustainable and eco-friendly future. Check them out</p>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="btn-group">
                                <button type="button" className="btn btn-sm btn-primary">View Details</button>
                              </div>
                              <small className="text-muted">10/10/2023</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="col">
                        <div id="carbon-project-card" className="card shadow-sm">
                          <img src={carsImg} alt="cars" />
                          <div className="card-body">
                            <p className="card-text"><b>Company Z</b> accelerates green initiatives, investing in e-mobility for a sustainable and environmentally conscious future in transportation.</p>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="btn-group">
                                <button type="button" className="btn btn-sm btn-primary">View Details</button>
                              </div>
                              <small className="text-muted">10/10/2023</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row paginator-container">
                  <div id="pagination" className="col-md-8 fw-bold">
                    Showing page 1 of 10
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CarbonProjects;
