import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import axios from "axios";
import { useCookies } from "react-cookie"; 

import TopNavbar from "./TopNavbar";
import SidebarDashboard from "./SidebarDashboard";
import Config from "../../config/Config";

const Settings = () => {
  const [sidebar, setSidebar] = useState(false);
  const [cookies] = useCookies(["g_accessToken", "id", "companyId"]); 
  const [companyName, setCompanyName] = useState('')

  const [inputs, setInputs] = useState({
    company: "",
    logo: null,
    tagline: "",
    website_url: "",
    general_contact_email: "",
    contact_number: "",
    physical_address: "",
    linkedin: "",
    twitter: "",
    facebook: "",
    instagram: "",
    registration_number: "",
    tax_identification_number: "",
    created_by: "",
  });

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: type === "file" ? e.target.files[0] : value,
    }));
  };

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const accessToken = cookies.g_accessToken; // Retrieve access token from cookies
      // console.log(accessToken);
      const formData = new FormData();
      const id = cookies.id;
      const companyId = cookies.companyId

      formData.append("logo", inputs.logo);
      formData.append("tagline", inputs.tagline);
      formData.append("website_url", inputs.website_url);
      formData.append("general_contact_email", inputs.general_contact_email);
      formData.append("contact_number", inputs.contact_number);
      formData.append("physical_address", inputs.physical_address);
      formData.append("linkedin", inputs.linkedin);
      formData.append("twitter", inputs.twitter);
      formData.append("facebook", inputs.facebook);
      formData.append("instagram", inputs.instagram);
      formData.append("registration_number", inputs.registration_number);
      formData.append(
        "tax_identification_number",
        inputs.tax_identification_number
      );
      formData.append("created_by", id);
      formData.append("company", companyId);

      const response = await axios.post(
        `${Config.apiBaseUrl}/v1/company-profiles/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`, // Include the access token in the authorization header
          },
        }
      );
      console.log("Form data submitted successfully:", response.data);
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
    }
  } 

  // get the company name using the companyId cookies
  const getCompanyName = async () => {
    try {
      const companyNameResponse = await axios.get(`${Config.apiBaseUrl}/v1/companies/${cookies.companyId}/`);
      const comapanyName = setCompanyName(companyNameResponse.data.name)
      console.log(comapanyName)
    
    } catch (error) {
      console.log('There was an error in getting the company name')  
    }
  }
  useEffect(() => {
    getCompanyName();
  }, []); 

  return (
    <div>
      {/* Sidebar Dashboard */}
      <SidebarDashboard sidebar={sidebar} />
      <section className="home-section">
        {/* Dashboard Navbar */}
        <TopNavbar toggleSidebar={toggleSidebar} />

        {/* Body content */}
        <div className="home-content mb-4">
          <div className="sales-boxes responsive">
            <div className="recent-sales box">
              <div className="container-fluid mt-3">
                <form className="settings-form" onSubmit={handleSubmit}>
                  <div className="settings form-group">
                    <h2>Basic Information</h2>
                    <label>
                      Company Name:
                      <input
                        type="text"
                        name="company"
                        className="form-control"
                        value={companyName}
                        disabled
                      />
                      </label>
                    <label>
                      Logo:
                      <input type="file" name="logo" className="form-control" accept="image/*" onChange={handleChange}/>
                    </label>
                    <label>
                      Tagline or Mission Statement:
                      <input
                        type="text"
                        name="tagline"
                        className="form-control"
                        value={inputs.tagline}
                        onChange={handleChange}
                      />
                    </label>
                    <label>
                      Website URL:
                      <input
                        type="url"
                        name="website_url"
                        className="form-control"
                        value={inputs.website_url}
                        onChange={handleChange}
                      />
                    </label>
                  </div>

                  <div className="settings form-group">
                    <h2>Contact Details</h2>
                    <label>
                      General Contact Email:
                      <input
                        type="email"
                        name="general_contact_email"
                        className="form-control"
                        value={inputs.general_contact_email}
                        onChange={handleChange}
                      />
                    </label>
                    <label>
                      Contact Number:
                      <input
                        type="tel"
                        name="contact_number"
                        className="form-control"
                        value={inputs.contact_number}
                        onChange={handleChange}
                      />
                    </label>
                    <label>
                      Physical Address:
                      <input
                        type="text"
                        name="physical_address"
                        className="form-control"
                        value={inputs.physical_address}
                        onChange={handleChange}
                      />
                    </label>
                  </div>

                  <div className="settings form-group">
                    <h2>Social Media Links</h2>
                    <label>
                      LinkedIn:
                      <input
                        type="url"
                        name="linkedin"
                        className="form-control"
                        value={inputs.linkedin}
                        onChange={handleChange}
                      />
                    </label>
                    <label>
                      Twitter:
                      <input
                        type="url"
                        name="twitter"
                        className="form-control"
                        value={inputs.twitter}
                        onChange={handleChange}
                      />
                    </label>
                    <label>
                      Facebook:
                      <input
                        type="url"
                        name="facebook"
                        className="form-control"
                        value={inputs.facebook}
                        onChange={handleChange}
                      />
                    </label>
                    <label>
                      Instagram:
                      <input
                        type="url"
                        name="instagram"
                        className="form-control"
                        value={inputs.instagram}
                        onChange={handleChange}
                      />
                    </label>
                  </div>

                  <div className="settings form-group">
                    <h2>Legal Information</h2>
                    <label>
                      Registration Number:
                      <input
                        type="text"
                        name="registration_number"
                        className="form-control"
                        value={inputs.registration_number}
                        onChange={handleChange}
                      />
                    </label>
                    <label>
                      Tax Identification Number:
                      <input
                        type="text"
                        name="tax_identification_number"
                        className="form-control"
                        value={inputs.tax_identification_number}
                        onChange={handleChange}
                      />
                    </label>
                  </div>

                  <input
                    type="submit"
                    value="Submit"
                    className="btn btn-primary"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Settings;
