import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const VerticalBarChart = ({ data }) => {
  const chartData = [
    { name: "Direct", Energy: data.direct_emission },
    { name: "Energy Indirect", Energy: data.energy_indirect_emission },
    { name: "Other Indirect", Energy: data.other_indirect_emission },
  ];

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        marginBottom: "1.5rem",
        marginTop: "5rem",
        padding: "1rem",
        boxShadow: "0.5px 1px 3px 2px rgba(80, 165, 98, 0.5)",
        borderRadius: "0.5rem",
      }}
    >
      <h6
        style={{
          color: "#717171",
          fontSize: "12px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        GHG Emission
      </h6>
      <ResponsiveContainer width="100%" height="80%">
        <BarChart
          data={chartData}
          margin={{ top: 5, right: 30, left: 20, bottom: 50 }}
          style={{ maxWidth: "100%", maxHeight: "100%" }}
          barCategoryGap="5%"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            angle={-45}
            textAnchor="end"
            tick={{ fontSize: 12 }}
          />
          <YAxis />
          <Tooltip />
          <Legend
            verticalAlign="top"
            align="left"
            wrapperStyle={{ fontSize: "10px", paddingBottom: '20px' }} // Adjust this value to change the font size
          />
          <Bar dataKey="Energy" fill={COLORS[0]} name="Emission Levels" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default VerticalBarChart;
