import React from 'react'
import esg from '../../assets/esg.jpg';

const EducationalInitiatives = () => {
  return (
    <div className="container mt-3">
      <div className="row">
        <h2  className="pb-2 border-bottom fw-bold"><span className="service-heading">Educational </span>Initiatives</h2>  
        <div className="row row-cols-1 row-cols-md-3 mb-3 text-center mt-2 py-2">
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm border-success">
              <div className="card-header py-3 text-bg-success border-success">
                <h4 className="my-0 fw-normal"> ESG Awareness and CSR Education</h4>
              </div>
              <div className="card-body">
                <p>GreenLead actively engages with the corporate world to educate on Environmental, Social, 
                  and Governance (ESG) principles and Corporate Social Responsibility (CSR). 
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            
            <div className="card mb-4 rounded-3 shadow-sm border-success" >
              <img src={esg} className="  rounded-3" alt="" loading="lazy" />
              <div className="card-body">
                
                  {/* <a href="#" className="btn btn-success">Get Started</a>  */}
              </div>
            </div>
            
          </div>
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm border-success">
              <div className="card-header py-3 text-bg-success border-success">
                <h4 className="my-0 fw-normal">Environmental Education for the Next Generation</h4>
              </div>
              <div className="card-body">
                <p>
                  GreenLead actively participates in programs that teach the importance of environmental 
                  stewardship to schools and educational institutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EducationalInitiatives