import React from "react";
import { UserDropdown } from "../EnvironmentalMultiform";

const Water = ({
  formData3,
  setFormData3,
  handleInputChange,
  waterSourcesOptions,
  customWaterSourcesOptions,
}) => {
  return (
    <div>
      <h2 className="fw-bold">Water (GRI 303):</h2>
      <p className="m-2">GRI 303-1: Water Withdrawal</p>
      <div className="m-3">
        <div>
          <div className="d-flex mb-5 align-items-center">
            <label htmlFor="source1-type" className="me-2">
              303-3 Water Withdrawal by Source 1: (surface water/groundwater)
            </label>
            <UserDropdown
              options={waterSourcesOptions}
              value={formData3.water_source1}
              onChange={(e) => handleInputChange(e, setFormData3)}
              name="water_source1"
            />
          </div>

          <div className="mb-5 mt-3 d-flex align-items-center">
            <label htmlFor="source1-amount" className="me-2 flex-1">
              Amount (cubic metres):
            </label>
            <input
              type="number"
              className="custom-input flex-1"
              id="source1-amount"
              value={formData3.amount1_water}
              name="amount1_water"
              onChange={(e) => handleInputChange(e, setFormData3)}
            />
          </div>

          <div className="mb-5 d-flex">
            <label htmlFor="source1-list" className="me-2 flex-1">
              List them:
            </label>
            <textarea
              id="source1-list"
              name="source1_water"
              value={formData3.source1_water}
              className="custom-input flex-1"
              rows="3"
              onChange={(e) => handleInputChange(e, setFormData3)}
            ></textarea>
          </div>
        </div>

        <div>
          <div className="d-flex mb-5">
            <label htmlFor="source2-type" className="me-2">
              303-3 Water Withdrawal by Source 2: (surface water/groundwater)
            </label>
            <UserDropdown
              options={waterSourcesOptions}
              value={formData3.water_source2}
              onChange={(e) => handleInputChange(e, setFormData3)}
              name="water_source2"
            />
          </div>

          <div className="mb-5 mt-3 d-flex align-items-center">
            <label htmlFor="source2-amount" className="me-2 flex-1">
              Amount (cubic metres):
            </label>
            <input
              type="number"
              className="custom-input flex-1"
              id="source2-amount"
              name="amount2_water"
              value={formData3.amount2_water}
              onChange={(e) => handleInputChange(e, setFormData3)}
            />
          </div>

          <div className="mb-5 d-flex">
            <label htmlFor="source2-list" className="me-2 flex-1">
              List them:
            </label>
            <textarea
              id="source2-list"
              className="custom-input flex-1"
              rows="3"
              name="source2_water"
              value={formData3.source2_water}
              onChange={(e) => handleInputChange(e, setFormData3)}
            ></textarea>
          </div>
        </div>

        <div className="d-flex">
          <label htmlFor="affected-water-sources" className="mb-2 flex-1">
            303-2 Water Sources Significantly Affected by Withdrawal of Water:
          </label>
          <textarea
            id="affected-water-sources"
            name="water_source_affected"
            value={formData3.water_source_affected}
            onChange={(e) => handleInputChange(e, setFormData3)}
            className="custom-input flex-1"
            rows="3"
          ></textarea>
        </div>

        <div>
          <p className="mb-5 mt-5">
            303-4 (Water Discharges by Quality and Destination):
          </p>
          <div className="mb-5 d-flex align-items-center">
            <label htmlFor="water-destination" className="me-2 flex-1">
              Destination:
            </label>
            <UserDropdown
              options={customWaterSourcesOptions}
              value={formData3.water_discharge}
              name="water_discharge"
              onChange={(e) => handleInputChange(e, setFormData3)}
            />
          </div>

          <div className="mb-3 d-flex">
            <label htmlFor="water-details" className="mb-1 flex-1">
              Details:
            </label>
            <textarea
              id="water-details"
              name="details"
              value={formData3.details}
              onChange={(e) => handleInputChange(e, setFormData3)}
              className="custom-input flex-1"
              rows="3"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Water;
