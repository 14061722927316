import React from 'react';

const Biodiversity = ({ formData4, handleInputChange, setFormData4}) => {
  return (
    <div>
      <h2 className='fw-bold'>GRI 304: Biodiversity</h2>
      <p className='m-2'>GRI 304-1: Operational Sites Owned, Leased, Managed in, or Adjacent to, Protected Areas and Areas of High Biodiversity Value Outside Protected Areas:</p>
      
      <div className='m-3'>
        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="protected-sites" className="me-2 mb-2 flex-1">Number of operational sites located within protected areas:</label>
          <input type="number" className="custom-input flex-1" id="protected-sites" name="operational_sites" value={formData4.operational_sites} onChange={(e) => handleInputChange(e, setFormData4)} />
        </div>

        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="adjacent-sites" className="me-2 mb-2 flex-1">Number of operational sites adjacent to protected areas:</label>
          <input type="number" className="custom-input flex-1" id="adjacent-sites" name="adjacent_operational_sites" value={formData4.adjacent_operational_sites} onChange={(e) => handleInputChange(e, setFormData4)} />
        </div>

        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="outside-protected-sites" className="me-2 mb-2 flex-1">Number of operational sites located outside of protected areas:</label>
          <input type="number" className="custom-input flex-1" id="outside-protected-sites" name="sites_outside_protected_areas" value={formData4.sites_outside_protected_areas} onChange={(e) => handleInputChange(e, setFormData4)} />
        </div>

        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="biodiversity-sites" className="me-2 mb-2 flex-1">Number of operational sites located in areas of high biodiversity value outside protected areas:</label>
          <input type="number" className="custom-input flex-1" id="biodiversity-sites" name="sites_located_in_high_biodiversity_areas" value={formData4.sites_located_in_high_biodiversity_areas} onChange={(e) => handleInputChange(e, setFormData4)} />
        </div>
      </div>
    </div>
  );
}

export default Biodiversity;

