import React from 'react'

const ForcedLabor = ({ formData16, setFormData16, handleInputChange }) => {
  return (
    <div>
      <h2 className='fw-bold'>GRI 409: Forced or Compulsory Labor:</h2>
    
      <div>
        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="forced_labor_incidents" className="me-2 mb-2 flex-1">(GRI 409-1): Incidents of Forced or Compulsory Labor </label>
          <textarea className='custom-input flex-1'  id="forced_labor_incidents" name="forced_labor_incidents" value={formData16.forced_labor_incidents} onChange={(e) => handleInputChange(e, setFormData16)} ></textarea>
        </div>

        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="actions_taken_against_forced_labor" className="me-2 mb-2 flex-1">(GRI 409-2): Actions Taken Against Forced or Compulsory Labor</label>
          <textarea className='custom-input flex-1' id="actions_taken_against_forced_labor" name="actions_taken_against_forced_labor" value={formData16.actions_taken_against_forced_labor} onChange={(e) => handleInputChange(e, setFormData16)}></textarea>
        </div>

    </div>
  </div>
  )
}

export default ForcedLabor