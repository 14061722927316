import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Login from './components/LoginSignup/Login'
import Signup from './components/LoginSignup/Signup'
import Dashboard from './pages/Dashboard';
import CarbonProjects from './components/Dashboard/CarbonProjects';
import Esg from './components/Dashboard/Esg';
import CarbonCredits from './components/Dashboard/CarbonCredits';
import Settings from './components/Dashboard/Settings';
import { CookiesProvider } from 'react-cookie';

function App() {
  return (
    <div className="App">
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <BrowserRouter>
      <Routes>
      <Route path="/" element={ <Home/> } />
        <Route path="/about" element={ <About/> } />
        <Route path='/login' element={<Login/>}/>
        {/* <Route path='/signup' element={<Signup/>}/> */}
        <Route path='/dashboard' element={<Dashboard/>}/>
        <Route path='/esg' element={<Esg/>}/>
        <Route path='/carbon-projects' element={<CarbonProjects/>}/>
        <Route path='/carbon-credits' element={<CarbonCredits/>}/>
        <Route path='/settings' element={<Settings/>} />
      </Routes>
      </BrowserRouter>
      </CookiesProvider>
    </div>
  );
}

export default App;
