import React from 'react';

const Emissions = ({ formData5, handleInputChange, setFormData5}) => {
  return (
    <div>
      <h2 className='fw-bold'>GRI 305: Emissions</h2>
      <div className='m-3'>
        <div className="mb-4">
          <h4 className='mb-4'>GRI 305-1: Direct (Scope 1) GHG emissions:</h4>
          <div className='mb-4 d-flex align-items-center'>
            <label htmlFor="direct-amount" className="me-2 mb-3 mt-2 flex-1">Amount (metric tonnes):</label>
            <input type="number" id="direct-amount" name="direct_emission" className="custom-input flex-1" value={formData5.direct_emission} onChange={(e) => handleInputChange(e, setFormData5)} />
          </div>
          <div className='mb-4 d-flex'>
            <label htmlFor="direct-sources" className="me-2 flex-1">List the sources:</label>
            <textarea id="direct-sources" name="source_direct_emission" className="custom-input flex-1" rows="3" value={formData5.source_direct_emission} onChange={(e) => handleInputChange(e, setFormData5)}></textarea>
          </div>
        </div>

        <div className="mb-4">
          <h4 className='mb-4'>GRI 305-2: Energy indirect (Scope 2) GHG emissions:</h4>
          <div className='mb-4 d-flex'>
            <label htmlFor="energy-amount" className="me-2 mb-3 flex-1" >Amount (metric tonnes):</label>
            <input type="number" id="energy-amount" className="custom-input flex-1" name="energy_indirect_emission" value={formData5.energy_indirect_emission} onChange={(e) => handleInputChange(e, setFormData5)} />
          </div>
          <div className='mb-4 d-flex'>
            <label htmlFor="energy-sources" className="me-2 flex-1">List the sources:</label>
            <textarea id="energy-sources" name="source_energy_indirect_emission" className="custom-input flex-1" rows="3" value={formData5.source_energy_indirect_emission} onChange={(e) => handleInputChange(e, setFormData5)}></textarea>
          </div>
        </div>

        <div className="mb-4">
          <h4 className='mb-4'>GRI 305-3: Other indirect (Scope 3) GHG emissions:</h4>
          <div className='d-flex mb-4'>
            <label htmlFor="other-amount" className="me-2 mb-3 flex-1">Amount (metric tonnes):</label>
            <input type="number" id="other-amount" className="custom-input flex-1" name="other_indirect_emission" value={formData5.other_indirect_emission} onChange={(e) => handleInputChange(e, setFormData5)} />
          </div>
          <div className='d-flex mb-4'>
            <label htmlFor="other-sources" className="me-2 flex-1">List the sources:</label>
            <textarea id="other-sources" name="source_other_indirect_emission" className="custom-input flex-1" rows="3" value={formData5.source_other_indirect_emission} onChange={(e) => handleInputChange(e, setFormData5)}></textarea>
          </div>
        </div>

        <div>
          <h4 className='mb-4'>GRI 305-5: Reductions in Other Significant GHG Emissions:</h4>
          <div className='d-flex'>
            <label htmlFor="reductions" className="me-2 mb-2 flex-1">Reductions in Other Significant GHG Emissions:</label>
            <textarea id="reductions" name="reduction_plan" className="custom-input flex-1" rows="3" value={formData5.reduction_plan} onChange={(e) => handleInputChange(e, setFormData5)}></textarea>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Emissions;

