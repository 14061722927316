import React from 'react';
import collinsImg from '../../assets/Collins2.jpg'
import keithImg from '../../assets/keith.jpg'
import { Link } from 'react-router-dom';
import './About.css'

const AboutTeam = () => {
  return (
    <div>
      <section id="team" className="mt-5 mb-2 team section-bg">
        <div className="container d-flex flex-column" data-aos="fade-up">

          <div className="section-title">
            <h2 className="fw-bold border-bottom">Team</h2>
            <p>Check our Team</p>
          </div>

          <div className="row mt-5">

            <div className="col-md-6">
              <div className="member" data-aos="fade-up" data-aos-delay="100">
                <div className="pic"><img src={collinsImg} width="350px" height="350px" alt="" /></div>
                <div className="member-info">
                  <h4>Collins Nyakoe Abaya</h4>
                  <span>Founder</span>
                  <div className="social">
                    <Link to=""><i className='bx bxl-twitter'></i></Link>
                    <Link to=""><i className='bx bxl-facebook-circle'></i></Link>
                    <Link to=""><i className='bx bxl-instagram-alt' ></i></Link>
                    <Link to=""><i className='bx bxl-linkedin-square'></i></Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="member" data-aos="fade-up" data-aos-delay="100">
                <div className="pic"><img src={keithImg} width="350px" height="350px" alt="" /></div>
                <div className="member-info">
                  <h4>Keith Koki</h4>
                  <span>Co-Founder</span>
                  <div className="social">
                    <Link to=""><i className='bx bxl-twitter'></i></Link>
                    <Link to=""><i className='bx bxl-facebook-circle'></i></Link>
                    <Link to=""><i className='bx bxl-instagram-alt' ></i></Link>
                    <Link to=""><i className='bx bxl-linkedin-square'></i></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutTeam;
