import React, { useState } from "react";
import Progressbar from "./Progressbar";
import axios from "axios";
import {
  Biodiversity,
  EffluentsWaste,
  Emissions,
  Energy,
  Introduction,
  Materials,
  Water,
} from "./EnvironmentalIndividualForms";

import { useCookies } from "react-cookie";
import Config from "../../../config/Config";


// Define UserDropdown component
export const UserDropdown = ({ options, value, onChange, name }) => {
  return (
    <select
      value={value}
      onChange={onChange}
      name={name}
      className="form-select flex-1"
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

// Define wasteTypeOptions
const wasteTypeOptions = [
  { value: "", label: "Select an option" },
  { value: "hazardous", label: "Hazardous waste" },
  { value: "non-hazardous", label: "Non-hazardous waste" },
  { value: "recycled waste", label: "Recycled waste" },
];

// Define disposalMethodOptions
const disposalMethodOptions = [
  { value: "", label: "Select an option" },
  { value: "landfill", label: "Landfill" },
  { value: "incineration", label: "Incineration" },
  { value: "recycling", label: "Recycling" },
  { value: "treatment", label: "Treatment" },
];

// Define renewableOptions
const renewableOptions = [
  { value: "", label: "Select an option" },
  { value: "Renewable", label: "Renewable" },
  { value: "Non-renewable", label: "Non-Renewable" },
];

// Define waterSourcesOptions
const waterSourcesOptions = [
  { value: "", label: "Select an option" },
  { value: "surface water", label: "Surface Water" },
  { value: "groundwater", label: "Groundwater" },
];

// Define waterSourcesOptions
const customWaterSourcesOptions = [
  { value: "", label: "Select an option" },
  { value: "surface water bodies", label: "Surface Water Bodies" },
  { value: "groundwater", label: "Groundwater" },
  { value: "reuse", label: "Reuse" },
];

// custom hook to manage the state for different forms
const useFormState = (fields) => {
  const initialState = fields.reduce((state, field) => {
    state[field.name] = field.initialValue;
    return state;
  }, {});

  const [formData, setFormData] = useState(initialState);

  return [formData, setFormData];
};

const MultiStepForm = ({ setActiveComponent }) => {
  const [cookies] = useCookies(["id", "companyId"]);

  // State variable to hold the response data
  const [responseData, setResponseData] = useState(null);

  // Rest of the component code
  const [formData1, setFormData1] = useFormState([
    { name: "materials_used", initialValue: 0 },
    { name: "recycled_materials", initialValue: 0 },
    { name: "source_materials", initialValue: "" }
  ]);
  const [formData2, setFormData2] = useFormState([
    { name: "energy_source1", initialValue: "" },
    { name: "amount1_energy", initialValue: 0 },
    { name: "source1_energy", initialValue: "" },
    { name: "energy_source2", initialValue: "" },
    { name: "amount2_energy", initialValue: 0 },
    { name: "source2_energy", initialValue: "" },
  ]);
  const [formData3, setFormData3] = useFormState([
    { name: "water_source1", initialValue: "" },
    { name: "amount1_water", initialValue: 0 },
    { name: "source1_water", initialValue: "" },
    { name: "water_source2", initialValue: "" },
    { name: "amount2_water", initialValue: 0 },
    { name: "source2_water", initialValue: "" },
    { name: "water_source_affected", initialValue: "" },
    { name: "water_discharge", initialValue: "" },
    { name: "details", initialValue: "" },
  ]);
  const [formData4, setFormData4] = useFormState([
    { name: "operational_sites", initialValue: 0 },
    { name: "adjacent_operational_sites", initialValue: 0 },
    { name: "sites_outside_protected_areas", initialValue: 0 },
    { name: "sites_located_in_high_biodiversity_areas", initialValue: 0 },
  ]);
  const [formData5, setFormData5] = useFormState([
    { name: "direct_emission", initialValue: 0 },
    { name: "source_direct_emission", initialValue: "" },
    { name: "energy_indirect_emission", initialValue: 0 },
    { name: "source_energy_indirect_emission", initialValue: "" },
    { name: "other_indirect_emission", initialValue: 0 },
    { name: "source_other_indirect_emission", initialValue: "" },
    { name: "reduction_plan", initialValue: "" },
  ]);
  const [formData6, setFormData6] = useFormState([
    { name: "waste1", initialValue: "" },
    { name: "amount1_waste", initialValue: 0 },
    { name: "disposal1_waste", initialValue: "" },
    { name: "waste2", initialValue: "" },
    { name: "amount2_waste", initialValue: 0 },
    { name: "disposal2_waste", initialValue: "" },
    { name: "waste3", initialValue: "" },
    { name: "amount3_waste", initialValue: 0 },
    { name: "disposal3_waste", initialValue: "" },
    { name: "transportation_of_waste", initialValue: "" },
  ]);

  // check for the current step
  const [currentStep, setCurrentStep] = useState(1);

  const handleInputChange = (e, setFormData) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleNext = () => {
    if (currentStep < 7) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async () => {
    const userId = cookies.id;
    /////

    const allFormData = {
      ...formData1,
      ...formData2,
      ...formData3,
      ...formData4,
      ...formData5,
      ...formData6,
      created_by: userId,
    };

    //  Send allFormData to Django endpoint using Axios
    try {
      const companyId = cookies.companyId;

      // localStorage.getItem('g_accessToken');
      const response = await axios.post(
        `${Config.apiBaseUrl}/v1/companies/${companyId}/environmental/`,
        allFormData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setResponseData(response.data);
      console.log("Form submitted successfully:", response.data);

      // After form submission, show the SocialMultiform
      setActiveComponent("SocialMultiform");

    } catch (error) {
      // Handle specific errors
      if (error.isAxiosError) {
        // Check if it's an Axios error
        if (error.response) {
          // The request was made and the server responded with a code that falls out of the range of 2xx
          console.error("Status:", error.response.status);
          console.error("Error message:", error.response.data);
        } else if (error.request) {
          // The request was made but no response was received e.g. Server connection error
          console.error("Network error:", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error:", error.message);
        }
      } else {
        // Handle other non-Axios errors (optional)
        console.error("Unexpected error:", error);
      }
    }
  };

  const renderFormInputs = () => {
    switch (currentStep) {
      // Introduction
      case 1:
        return <Introduction />;

      // Materials form
      case 2:
        return (
          <Materials
            handleInputChange={handleInputChange}
            formData1={formData1}
            setFormData1={setFormData1}
          />
        );

      // {/*// Energy form */}
      case 3:
        return (
          <Energy
            handleInputChange={handleInputChange}
            formData2={formData2}
            setFormData2={setFormData2}
            renewableOptions={renewableOptions}
          />
        );

      // {/* // Water form */}
      case 4:
        return (
          <Water
            handleInputChange={handleInputChange}
            formData3={formData3}
            setFormData3={setFormData3}
            waterSourcesOptions={waterSourcesOptions}
            customWaterSourcesOptions={customWaterSourcesOptions}
          />
        );

      // {/* // Biodiversity form */}
      case 5:
        return (
          <Biodiversity
            handleInputChange={handleInputChange}
            formData4={formData4}
            setFormData4={setFormData4}
          />
        );

      //  {/* // Emissions form */}
      case 6:
        return (
          <Emissions
            handleInputChange={handleInputChange}
            formData5={formData5}
            setFormData5={setFormData5}
          />
        );

      // {/* // Effluents and Waste */}
      case 7:
        return (
          <EffluentsWaste
            handleInputChange={handleInputChange}
            formData6={formData6}
            setFormData6={setFormData6}
            wasteTypeOptions={wasteTypeOptions}
            disposalMethodOptions={disposalMethodOptions}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className="container mt-3">
      <div className="overview-boxes">
          <div className="box" id="report-form">
            <div className="right-side">
              <form action="">
                {renderFormInputs()}
                <br />
                {currentStep > 1 && (
                  <button
                    className="prev-btn mt-2 btn btn-secondary rounded-pill me-2"
                    type="button"
                    onClick={handlePrevious}
                  >
                    Previous
                  </button>
                )}
                {currentStep < 7 ? (
                  <button
                    type="button"
                    className="next-btn mt-2 btn btn-success rounded-pill"
                    onClick={handleNext}
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    className="next-btn mt-2 btn btn-success rounded-pill"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
              </form>
              <div>
                {/* Progress bar */}
                <Progressbar currentStep={currentStep} />
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default MultiStepForm;
