import React from "react";

const Services = () => {
  return (
    <div className="container col" id="hanging-icons">
      <h2 className="pb-2 border-bottom fw-bold">
        Ser<span className="service-heading">vices</span>
      </h2>
      <div className="row g-3 py-3">
        <section id="facts" className="facts">
          <div className="container p-2">
            <div className="row no-gutters">
              <div
                className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch"
                data-aos="fade-up"
              >
                <div className="count-box">
                  <span>ESG Data Analysis</span>
                  <p>
                    <strong>
                      GreenLead automates ESG data analysis against previous
                      reports and reporting standards for informed
                      decision-making
                    </strong>
                  </p>
                </div>
              </div>

              <div
                className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="count-box">
                  <span>Reporting Framework Implementation</span>
                  <p>
                    <strong>
                      GreenLead offers easier-to-use reporting templates using
                      the GRI Reporting framework
                    </strong>
                  </p>
                </div>
              </div>

              <div
                className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="count-box">
                  <span>Performance Benchmarking</span>
                  <p>
                    <strong>
                      We offer benchmarking services to evaluate ESG performance
                      against peers, guiding improvement strategies.
                    </strong>
                  </p>
                </div>
              </div>

              <div
                className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="count-box">
                  <span>Stakeholder Engagement</span>
                  <p>
                    <strong>
                      Greenlead conducts materiality assessments and engages
                      stakeholders to prioritize ESG issues.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Services;
