import React from 'react'

const ChildLabor = ({ formData15, setFormData15, handleInputChange }) => {
  return (
    <div>
      <h2 className='fw-bold'>GRI 408: Child Labor:</h2>
      
      <div>
        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="child_labor_incidents" className="me-2 mb-2 flex-1">(GRI 408-1): Child Labor Incidents</label>
          <textarea className='custom-input flex-1' id="child_labor_incidents" name="child_labor_incidents" value={formData15.child_labor_incidents} onChange={(e) => handleInputChange(e, setFormData15)}></textarea>
        </div>

        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="actions_taken_against_child_labor" className="me-2 mb-2 flex-1">(GRI 408-2): Actions Taken Against Child Labor</label>
          <textarea className='custom-input flex-1'  id="actions_taken_against_child_labor" name="actions_taken_against_child_labor" value={formData15.actions_taken_against_child_labor} onChange={(e) => handleInputChange(e, setFormData15)}></textarea>
        </div>

      </div>
    </div>
  )
}

export default ChildLabor