import React from 'react'
import Navbar from '../components/Navbar'
import AboutHero from '../components/About/AboutHero'
import Footer from '../components/Footer'
import AboutTeam from '../components/About/AboutTeam'
import AboutApproach from '../components/About/AboutApproach'
import AboutFeatures from '../components/About/AboutFeatures'
import Contact from '../components/Contact'

const About = () => {
  return (
    <div>
      <Navbar/>
      <AboutHero/>
      <AboutFeatures/>
      <AboutApproach/>
      <AboutTeam/>
      <Contact/>
      <Footer/>
    </div>
  )
}

export default About