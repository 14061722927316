import React from 'react'

const NonDiscrimination = ({ formData13, setFormData13, handleInputChange }) => {
  return (
    <div>
      <h2 className='fw-bold'>GRI 406: Non-Discrimination:</h2>
      
      <div>
        
        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="list_non_discrimination_policies" className="me-2 mb-2 flex-1">(GRI 405-3): Non-Discrimination Policies</label>
          <textarea rows="3" className='custom-input flex-1' id="list_non_discrimination_policies" name="list_non_discrimination_policies" value={formData13.list_non_discrimination_policies} onChange={(e) => handleInputChange(e, setFormData13)} ></textarea>
        </div>

        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="list_incidents_discrimination" className="me-2 mb-2 flex-1">(GRI 406-2): Incidents of Discrimination</label>
          <textarea rows="3"  className='custom-input flex-1' id="list_incidents_discrimination" name="list_incidents_discrimination"  value={formData13.list_incidents_discrimination} onChange={(e) => handleInputChange(e, setFormData13)} ></textarea>
        </div>

      </div>
    </div>
  )
}

export default NonDiscrimination