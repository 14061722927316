import React from 'react';

const Materials = ({ formData1, setFormData1, handleInputChange}) => {
  return (
    <div>
      <h3 className='fw-bold mb-4'>GRI 301: Materials</h3>
      <div className='m-3'>
        <div className='mt-3'>
          <h4>GRI 301-1: Materials Used by Weight or Volume</h4>
          <div className='d-flex flex-column'>
            <div className='d-flex align-items-start'>
              <label className='mt-2 me-2 flex-1'>Materials Used by Weight or Volume:</label>
              <input 
                type="number" 
                name="materials_used" 
                id="materials_used" 
                className='mt-2 custom-input flex-1'
                value={formData1.materials_used}
                onChange={(e) => handleInputChange(e, setFormData1)}
              />
            </div>
          </div>
        </div>

        <div>
          <h4 className='mt-5'>GRI 301-2: Recycled Input Materials Used</h4>
          <div className='d-flex flex-column'>
            <div className='d-flex align-items-start mb-5'>
              <label className='mt-2 me-2 flex-1'>Amount Materials Used: </label>
              <input 
                type="number" 
                name="recycled_materials" 
                id="recycled_materials" 
                className='mt-2 custom-input flex-1'
                value={formData1.recycled_materials}
                onChange={(e) => handleInputChange(e, setFormData1)}
              />
            </div>
            <div className='d-flex'>
              <label className='mt-2 me-2 flex-1'>Sources of Materials Used: </label>
              {/* user enters a list of resources */}
              <textarea
                name="source_materials" 
                id="source_materials" 
                className='custom-input mt-1 flex-1'
                rows="3"
                value={formData1.source_materials}
                onChange={(e) => handleInputChange(e, setFormData1)}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>   
  );
}

export default Materials;

