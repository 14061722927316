import React from 'react'
import './Forms.css'

const SocialProgressbar = ({currentStep}) => {
  return (
    <div className="container">
        <div className="row">
          <div className="progressbar">
          {/* show a small percentage of the progress bar when the user goes to the next page */}
          <div style={{ width: currentStep === 0 ? "9.09%" : currentStep === 1 ? "18.18%" : 
          currentStep === 2 ? "27.27%" : currentStep === 3 ? "36.36%" :
          currentStep === 4 ? "45.45%" :  currentStep === 5 ? "54.54%" :
           currentStep === 6 ? "63.63%": currentStep === 7 ? "72.72%" :
           currentStep === 8 ? "81.81%" : currentStep === 9 ? "90.90%" : currentStep === 10 ? "100%" : "100%"}}></div>
        </div> 
      </div>
    </div>
  )
}

export default SocialProgressbar