import React from 'react'

const Freedom = ({ formData14, setFormData14, handleInputChange }) => {
  return (
    <div>
      <h2 className='fw-bold'>GRI 407: Freedom of Association and Collective Bargaining:</h2>
      
      <div>
        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="policies_freedom_association" className="me-2 mb-2 flex-1">(GRI 407-1): Freedom of Association</label>
          <textarea rows="3" className='custom-input flex-1' id="policies_freedom_association" name="policies_freedom_association" value={formData14.policies_freedom_association} onChange={(e) => handleInputChange(e, setFormData14)}></textarea>
        </div>

        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="list_agreements_with_labour_unions" className="me-2 mb-2 flex-1">(GRI 407-2): Collective Bargaining Agreements </label>
          <textarea rows="3" className='custom-input flex-1' id="list_agreements_with_labour_unions" name="list_agreements_with_labour_unions" value={formData14.list_agreements_with_labour_unions} onChange={(e) => handleInputChange(e, setFormData14)}></textarea>
        </div>

      </div>
    </div>
  )
}

export default Freedom