import React from 'react';
import './home.css';
import main from '../../assets/finalVideo.mp4';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    // hero section
    <>
      <div id="hero" className="container col-xxl-12 mt-1">
        <div className="row flex-lg-row-reverse align-items-center g-2 py-2">
          <div className="col-10 col-sm-8 col-lg-5">
            <video autoPlay muted loop>
              <source src={main} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="col-lg-7">
            <h1 className="fw-bold lh-3 mb-2">
              <span className="releaf">ESG Reporting Digital Solutions.</span>
            </h1>
            <p className="mt-4">GreenLead transforms ESG reporting with an open-data digital
platform designed for seamless sustainability reporting using the GRI
Reporting framework. Our platform features user-friendly templates, making
reporting easier and faster. We empower businesses to achieve their CSR
goals, fostering transparency and accountability. Join us on the journey to a
sustainable future, where environmental responsibility meets innovation.</p>
            <div className="gap-2 d-md-flex justify-content-sm-start">
              <Link className="btn btn-success px-4 me-md-2" to="/dashboard">Dashboard</Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container col-xxl-12 mt-2">
        <div className="row py-3 g-2">
          <div id="wave" className="card border-success mb-3">
            <div className="card-body">
              <p>
                <span className=" fw-bold releaf1">GreenLead </span>
                enables companies to voluntarily share data on emissions, climate risks, and social/administrative structures. Our AI-enhanced reporting system tracks relevant metrics, offering Automated Data Visualization, Anomaly Detection, and personalized insights. This ensures efficient monitoring of ongoing performances, providing valuable trends or comparisons based on a company's historical data or industry benchmarks. GreenLead facilitates regular updates and comprehensive reporting for sustainable initiatives.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
