import React from 'react'
import { UserDropdown } from '../SocialMultiform'

const LaborManagement = ({ formData9, setFormData9, collectiveBargainigOptions, handleInputChange }) => {
  return (
    <div>
      <h2 className='fw-bold'>GRI 402: Labor/Management Relations</h2>
      
      <div>
        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="empNo" className="me-2 mb-2 flex-1">(GRI 402-1): Collective Bargaining Agreements</label>
          <UserDropdown options={collectiveBargainigOptions} name='collective_bargaining_agreements' value={formData9.collective_bargaining_agreements} onChange={(e) => handleInputChange(e, setFormData9)}/>
        </div>

        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="total_number_strikes_lockouts" className="me-2 mb-2 flex-1">(GRI 402-2): Strikes and Lockouts</label>
          <input type="number" className='custom-input flex-1' id="total_number_strikes_lockouts" name="total_number_strikes_lockouts" value={formData9.total_number_strikes_lockouts} onChange={(e) => handleInputChange(e, setFormData9)}/>
        </div>

        <div className="mb-4 d-flex align-items-center">
          <label htmlFor="list_disputes_due_to_labor_practices" className="me-2 mb-2 flex-1">(GRI 402-3): Disputes Related to Labor Practices</label>
          <textarea className='custom-input flex-1' rows='3' id="list_disputes_due_to_labor_practices" name="list_disputes_due_to_labor_practices" value={formData9.list_disputes_due_to_labor_practices} onChange={(e) => handleInputChange(e, setFormData9)}></textarea>
        </div>
      </div>
    </div>
  )
}

export default LaborManagement